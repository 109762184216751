import React from "react";
import { Switch, Route } from "react-router-dom";
import Video from "./Index";
import VideoWebsocket from "./VideoWebsocket";

export default () => (
  <Switch>
    <Route exact path="/livestreamwithtemplate" component={Video} />
    <Route
      exact
      path="/livestreamwithtemplate/icing"
      component={VideoWebsocket}
    />
  </Switch>
);
