// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Table, Pagination } from 'antd'

class TableLog extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { paginate, total, data, handleChangePage, column } = this.props

    return (
      <div className='table-log'>
        <Table
          size='small'
          title={() => (
            <span className='table-log-title'>
              {i18n[this.props.language].m.monitorGPSLog}
            </span>
          )}
          rowKey={(record) => record.id}
          columns={column}
          dataSource={data}
          pagination={false}
          scroll={{ x: 1000, y: 1000 }}
          bordered
          className='table-row'
        ></Table>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={paginate.page}
            page={paginate.page}
            pageSize={paginate.pageSize}
            total={total}
            onChange={(page, pageSize) => handleChangePage(page, pageSize)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(TableLog)
