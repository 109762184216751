const th = {
  a: {
    approachRange: "ระยะเข้าใกล้ขอบเขต (เมตร)",
    accRedPolicy: "ความเร่ง (กม./ชั่วโมง ภายใน 1 วินาที)",
    Act: "พรบ.",
    Actno: "หมายเลขกรมธรรม์ พรบ.",
    AverageTrip: "การเดินทางโดยเฉลี่ย",
    addLocation: "เพิ่มสถานที่",
    editLocation: "แก้ไขสถานที่",
    allTruck: "รถทั้งหมด",
    accessory: "อุปกรณ์เสริม",
    acceleration: "ความเร่ง",
    account: "บัญชีผู้ใช้",
    accountName: "ผู้ใช้งาน",
    ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
    action: "ดำเนินการ",
    actionRequired: "แจ้งเตือนพร้อมเปิดเคส",
    actionTime: "เวลา",
    active: "Active",
    activeStatusImage: "รูปภาพแสดงสถานะกำลังใช้งาน",
    actualTransitDistance: "ระยะทางที่ขนส่งจริง",
    add: "เพิ่ม",
    addARow: "เพิ่มเเถว",
    addDrivinglicense: "ใบอนุญาตขับขี่อื่นๆ",
    addFromRouteMaster: "เพิ่มจากแผนการจัดส่ง",
    addInformation: "เพิ่มข้อมูล",
    addItemInList: "เพิ่มข้อมูลในรายการ",
    address: "ที่อยู่",
    addVehicleGroup: "เพิ่มกลุ่มพาหนะ",
    adjustTheTable: "ปรับตาราง",
    administrator: "เห็นข้อมูลแบบผู้ดูแลระบบ",
    advancedSetting: "ตั้งค่าขั้นสูง",
    affiliation: "ต้นสังกัด",
    african: "แอฟริกัน",
    after1day: "ผ่านไป 1 วัน",
    after1hour: "ผ่านไป 1 ชั่วโมง",
    after30min: "ผ่านไป 30 นาที",
    after4hour: "ผ่านไป 4 ชั่วโมง",
    after5min: "ผ่านไป 5 นาที",
    after8hour: "ผ่านไป 8 ชั่วโมง",
    agency: "เอเจนซี่",
    alarm: "Alarm",
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      "Alarm และ Alert แยกตามทะเบียน รายวัน",
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      "Alarm และ Alert แยกตามทะเบียน รายปี",
    alert: "Alert",
    alert_setting_filter: "กรุณาตั้งค่ารายงาน",
    alertAlarm: "ระบบการแจ้งเตือนการฝ่าฝืน",
    alertNotiOnly: "แจ้งเตือนอย่างเดียว",
    aliasTableCompany: "ชื่อย่อ",
    all: "ทั้งหมด",
    allowBOOMInside: "อนุญาตให้เปิด BOOM ในสถานที่เท่านั้น",
    allowBOOMOutside: "อนุญาตให้เปิด BOOM นอกสถานที่เท่านั้น",
    allowParkingInside: "อนุญาตให้จอดในสถานที่เท่านั้น",
    allowParkingOutside: "อนุญาตให้จอดนอกสถานที่เท่านั้น",
    allowPTODoorInside: "อนุญาตให้เปิด PTO/ประตู ในสถานที่เท่านั้น",
    allowPTODoorOutside: "อนุญาตให้เปิด PTO/ประตู นอกสถานที่เท่านั้น",
    allowSharedPartnerEdit: "ผู้ว่าจ้างสามารถแก้ไข",
    allowWorkingTime: "ช่วงเวลาที่อนุญาตทำงาน",
    allStatusDashCameras: "ทั้งหมด",
    amount: "ครั้ง",
    amountPlant: "จำนวนแพลนต์ใกล้เคียง",
    amountPlantIsRequired: "กรุณากรอกจำนวนแพลนต์ใกล้เคียง",
    amphur: "เขต/อำเภอ",
    and: "เเละ",
    announcement: "การประกาศ",
    announcementBroadcast: "กระจายข่าว / เผยแพร่ข่าว ...",
    apply: "ปรับใช้",
    appointment_date: "วัน-เวลานัดหมาย",
    appointment_place: "สถานที่นัด",
    appointmentLocation: "สถานที่นัดหมาย",
    arab: "อาหรับ",
    assignAt: "กำหนดเมื่อ",
    assignFirmware: "กำหนดเฟิร์มแวร์",
    assignTo: "ผู้รับมอบหมาย",
    attachment_type: "ประเภทไฟล์",
    available: "พร้อมใช้งาน",
    avgEarningJob: "Avg. Earning/Job",
    allowTechniciansToUse: "อนุญาตให้ช่างใช้งาน",
    allow: "อนุญาต",
    autoUploadSetting: "ตั้งค่าการอัปโหลดอัตโนมัติ",
    automaticaddeditdriverinformationbyusingdriverlicense:
      "เพิ่ม/เปลี่ยน ข้อมูลพนักงานขับขี่จากการรูดใบขับขี่อัตโนมัติ",
    automaticeditvehiclebyusingdriverlicense:
      "เปลี่ยนพาหนะสำหรับพนักงานขับขี่จากการรูดใบขับขี่อัตโนมัติ",
    assignedBy: "กำหนดโดย",
    audio: "เสียง",
  },
  b: {
    brakeRedPolicy: "เบรค (กม./ชั่วโมง ภายใน 1 วินาที)",
    beforeFuel: "ก่อนเติมน้ำมัน",
    afterFuel: "หลังเติมเชื้อเพลิง",
    back: "กลับ",
    base: "พื้นฐาน",
    baseTemplate: "Template พื้นฐาน",
    battery: "แบตเตอรี่",
    battery_samrong: "ค่าสัญญาณไฟแบตเตอรี่สำรอง",
    battery_car: "แบตเตอรี่รถ",
    battery_is_charge: "ชาร์จอยู่",
    battery_not_charge: "ไม่ได้ชาร์จอยู่",
    battery_status: "สถานะการชาร์จแบต",
    behavior: "พฤติกรรม",
    behavior_report_menu: "พฤติกรรม",
    boatDeliveryDashboard: "ระบบติดตามสถานะเรือ",
    boatDeliveryReport: "การเดินทางเรือ",
    body_email: "ข้อความเพิ่มเติม",
    bodyNo: "หมายเลขตัวถังพาหนะ",
    bodyNoOfInstall: "หมายเลขตัวรถที่ติดตั้ง",
    bookedDate: "วันที่จอง",
    booking: "งานจอง",
    bookingDate: "วันที่จอง",
    bookingOverview: "ภาพรวมงานจอง",
    bookingPool: "งานจอง",
    bookingRemark: "Booking Remark",
    BOOKINGSTATISTICS: "สถิติการจอง",
    BOOM: "BOOM",
    boxID: "รหัสกล่อง",
    boxNumber: "เบอร์กล่อง",
    brake: "เบรค",
    brand: "ยี่ห้อ",
    broken: "เสีย",
    button: "ปุ่ม",
    browseFiles: "โหลดไฟล์จากเครื่อง",
    brokenFile: "ไฟล์เสีย",
  },
  c: {
    car_registration_page: "หน้าเล่มทะเบียนรถ",
    cause: "สาเหตุ",
    currentPlant: "แพลนท์ปัจจุบัน",
    complete: "สำเร็จ",
    cardId: "เลขบัตรประชาชน : ",
    chauffeurDriver: "คนขับขี่",
    chauffeurDriverUnKnown: "ไม่มีชื่อคนขับขี่",
    camera_back: "กล้องในรถ",
    camera_extra: "กล้องเสริม",
    camera_position: "ตำแหน่งกล้อง",
    cancel: "ยกเลิก",
    cancelled: "ยกเลิก",
    cancelReason: "เหตุผลการยกเลิก",
    carNotOnline: "รถไม่ออนไลน์",
    carTax: "ภาษีรถยนต์",
    category: "หมวด",
    cc_email: "สำเนา",
    changeLanguage: "เปลี่ยนภาษา",
    changePassword: "เปลี่ยนรหัสผ่าน",
    changeProduct: "เปลี่ยนสินค้า",
    channel: "ช่องทาง",
    chasisSerialNumber: "หมายเลขตัวถัง",
    chauffeur: "พนักงานขับขี่",
    chineseKoreanJapanese: "จีน/ กาหลี/ญี่ปุ่น",
    clear: "ล้าง",
    clearUp: "ล้างค่า",
    close: "ปิด",
    closed: "ปิด",
    closeFixed: "เรียบร้อย-ปิดตั๋ว",
    closeNotAvailable: "รถไม่พร้อม-ปิดตั๋ว",
    closeJob: "ปิดงาน",
    closeJobDescribe: "คุณแน่ใจหรือที่จะปิดงานนี้?",
    cmd: "CMD",
    collapse: "ย่อ",
    Color: "สี",
    comment: "รายละเอียด",
    communityArea: "เขตชุมชน",
    company: "บริษัท",
    companyBilling: "บริษัทที่เรียกเก็บเงิน",
    companyCode: "รหัสบริษัท",
    companyIsRequired: "กรุณากรอกบริษัท",
    CompareVehicleSccoReport: "เปรียบเทียบพาหนะ SCCO",
    compulsory: "ประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)",
    concreteDeliveryDashboard: "สถานะการส่งคอนกรีต",
    configuration: "ปรับค่า",
    confirm: "ยืนยัน",
    confirm_appointment_place: "อาจมีการเปลี่ยนแปลงจังหวัด และอำเภอ",
    confirm_type: "ยืนยันการส่งโดย",
    confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
    confirmPassword: "ยืนยันรหัสผ่าน",
    confirmSendingBy: "ยืนยันการส่งโดย",
    confirmToAddThisItem: "ยืนยัน ต้องการเพิ่มรายการนี้",
    confirmToCancelThisItem: "ยืนยัน ต้องการยกเลิกรายการนี้",
    confirmToDeleteThisItem: "ยืนยัน ต้องการลบรายการนี้",
    confirmToEditThisItem: "ยืนยัน ต้องการเเก้ไขรายการนี้",
    confirmToCloseThisItem: "ยืนยัน การปิดรายการนี้",
    connection: "เชื่อมต่อสัญญาณ",
    connectionLost: "ขาดการติดต่อ",
    consignee: "ผู้รับสินค้า",
    consigneePhone: "เบอร์โทรศัพท์ผู้รับสินค้า",
    contact: "ติดต่อ",
    contact_name: "ชื่อบุคคลติดต่อ",
    contact_telno: "เบอร์ติดต่อ",
    Contactmanagement: "เบอร์โทรศัพท์พาหนะ",
    contactName: "ชื่อบุคคลติดต่อ",
    contactSupportForUserThisMenu:
      "เนื่องจากมีข้อมูลสถานที่เป็นจำนวนมาก กรุณาติดต่อซัพพอร์ทเพื่อใช้งานเมนูนี้",
    contactTelno: "เบอร์ติดต่อ",
    continuousDrive: "ทำงานต่อเนื่องได้ไม่เกิน",
    continuousDriveInADay: "ทำงานต่อเนื่องในหนึ่งวันได้ไม่เกิน",
    continuousDriving: "ขับรถต่อเนื่อง",
    contractor: "ผู้รับจ้าง",
    coordinates: "พิกัด",
    copyLink: "คัดลอกลิงก์",
    couldNotDisplayDirections: "ไม่สามารถแสดงเส้นทางได้เนื่องจาก: ไม่พบผลลัพธ์",
    courseName: "ชื่อหลักสูตร",
    create_trip: "สร้างทริป",
    created_by: "สร้างโดย",
    createdAt: "สร้างเมื่อ",
    createdBy: "สร้างโดย",
    createFrimware: "สร้างเฟิร์มเเวร์",
    creator: "ผู้สร้าง",
    criteria: "เงื่อนไข",
    criterionAndDeductMarksForTripSuccess:
      "เกณฑ์และการหักคะแนน ด้านความสำเร็จทริป",
    criticality: "ภาวะวิกฤต",
    criticalLevel: "ระดับความรุนแรง",
    crossroad: "ในทางเเยก",
    currentAddress: "ที่อยู่ปัจจุบัน",
    currentVehicle: "พาหนะปัจจุบัน",
    custom: "ปรับแต่ง",
    customer: "ลูกค้า",
    customerDetail: "รายละเอียดลูกค้า",
    customerID: "Customer ID",
    customerName: "ชื่อลูกค้า",
    customerType: "ประเภทลูกค้า",
    cycleOfDrumRotation: " จำนวนรอบการหมุนของโม่",
    calibratefuel: "Calibrate น้ำมัน Auto",
    condition_engineoff: "เงื่อนไขเฉพาะดับเครื่อง",
    cpfDashboard: "แดชบอร์ด CPF",
    creditamount: "เครดิตที่ยกมา",
    current_creditamount: "เครดิตจากการวิ่งปัจจุบัน",
    credit_available: "เครดิตที่สามารถเบิกได้",
    companysetting: "ตั้งค่าบริษัท",
    cut_fuel_engine_on_off: "ตัดข้อมูลน้ำมันตอนเปิด/ปิดเครื่องยนต์",
    cameraPosition: "ตำแหน่งกล้อง",
    cameraStatusDashboard: "แดชบอร์ดสถานะกล้อง",
    cameraPowerOff: "ตัดไฟกล้อง",
    cameraName: "ชื่อกล้อง",
  },
  d: {
    disableNotification: "ปิดเสียงแจ้งเตือน",
    drivingSpeed: "ความเร็วเริ่มต้น",
    drivingTimePolicy: "เวลาตามนโยบาย",
    drivingTimeWarning: "เวลาแจ้งเตือนก่อนถึงกำหนด",
    distanceNotice: "ระยะเวลาเว้นห่างในการแจ้งเตือนคนขับ",
    drive_surge: "ออกตัวกระชาก",
    driverDashboard: "สถานะคนขับรถ",
    downloading: "กำลังดาวน์โหลด",
    DrivingNamelicensescanlogReport: "ชื่อจากการรูดใบขับขี่",
    driverData: "ข้อมูลคนขับ",
    driverName: "ชื่อคนขับ :",
    durationRange: "ระยะเวลาสถานะ",
    daily: "รายวัน",
    DailyAlarmAndAlertReportByDriver:
      "Alarm และ Alert แยกตามพนักงานขับขี่ รายวัน",
    dailyCarStatusReport: "แยกสถานะพาหนะ รายวัน",
    dailyDrivingBehaviorReport: "พฤติกรรมการขับขี่ รายวัน",
    DailyiconsiamReport: "การให้บริการเรือ / รถ แบบรายวัน",
    DailyReport: "สรุปการวิ่งประจำวัน",
    dangerous: "อันตราย",
    dashboard: "แดชบอร์ด",
    dashCamera: "ระบบเฝ้าดูผ่านแดชแคม",
    data: "ข้อมูล",
    dataForDLT: "Data For DLT",
    dataSource: "แหล่งที่มาของข้อมูล",
    dataType: "ชนิดข้อมูล",
    dataTable: "ข้อมูลตาราง",
    dataVisibility: "การมองเห็นข้อมูล",
    date: "วันที่",
    date_entry: "วันที่เข้า",
    date_out: "วันที่ออก",
    date_time: "วันที่ และเวลา",
    date_time_entry: "วันที่-เวลาเข้า",
    date_time_out: "วันที่-เวลาออก",
    dateEnd: "วันที่สิ้นสุด",
    dateOfBirth: "วันเกิด",
    dateOfSubmission: "วันที่ยื่นคำขอ",
    dateRange: "ช่วงวันที่",
    dateRangeNotBeMoreThan31Days: "ช่วงวันที่ที่เลือกไม่ควรมากกว่า 31 วัน",
    dateStart: "วันที่เริ่มต้น",
    dateSubmit: "วันที่ส่งเอกสาร",
    dateTime: "วัน/เวลา",
    day: "วัน",
    daYouWantToReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    daysBeforeNotification: "จำนวนวันก่อนแจ้งเตือน",
    dayTime: "ช่วงเวลากลางวัน",
    ddHhMm: "(วัน:ชม:นาที)",
    deduct: "หัก",
    default: "ค่าเริ่มต้น",
    defaultImageBylocationType: "รูปค่าเริ่มต้น ตามชนิดสถานที่",
    defaultLang: "Default lang",
    defaultLatitude: "Default latitude",
    defaultLongitude: "Default longitude",
    delete: "ลบ",
    deletedAt: "ลบข้อมูลเมื่อ",
    deletedBy: "ลบข้อมูลโคย",
    deleteInformation: "ลบข้อมูล",
    deleteReason: "เหตุผลการลบ",
    deliver: "การขนส่ง",
    deliver_duration: "เวลาเริ่ม - เวลาสิ้นสุด",
    deliver_status: "สถานะส่งของ",
    Delivered: "ส่งของแล้ว",
    deliveryAddress: "ที่อยู่สำหรับการจัดส่ง",
    deliveryItem: "การส่งอุปกรณ์",
    deliveryItemDeleteHistoryPool: "ประวัติงานส่งอุปกรณ์ (EZY GPS)",
    deliveryItemPool: "งานส่งอุปกรณ์ (EZY GPS)",
    DeliveryReport: "การส่งสินค้า",
    deliveryStatus: "สถานะการจัดส่ง",
    deliverytrip: "ทริปการส่งของ",
    descrip: "รายละเอียด",
    description: "รายละเอียด",
    destination: "จุดหมาย",
    destinationDetail: "รายละเอียดจุดหมาย",
    destinationLocation: "สถานที่ปลายทาง",
    destinationLocation_duplicate_require: "สถานที่ปลายทางนี้มีในทริปนี้แล้ว",
    destinationLocation_require: "กรุณากรอกสถานที่ปลายทาง",
    destinationLocationcode: "รหัสสถานที่ปลายทาง",
    detail: "รายละเอียด",
    detail_problem: "อาการของปัญหา(อื่นๆ โปรดระบุ)",
    detailOfConcreteTruck: "รายละเอียดสำหรับรถบรรทุกคอนกรีต",
    detailproblem: "วิธีการแก้ไข",
    detailProblemServiceMoveDevice: "อาการของปัญหาที่ได้รับแจ้ง",
    device: "อุปกรณ์",
    deviceName: "ชื่ออุปกรณ์",
    devicePhone: "เบอร์โทรกล่อง",
    deviceType: "ประเภทอุปกรณ์",
    depositFuelcreditReason: "เหตุผลในการเพิ่มเครดิต",
    diff: "Diff",
    dischargeDenominator: "ค่าคำนวณปริมาณในการเท",
    disconnected: "ขาดการติดต่อ",
    displayTime: "เวลาการเเสดงผล",
    distance: "ระยะทาง",
    distance_less_than: "ระยะทางน้อยกว่า",
    distance_more_than: "ระยะทางที่ถือว่าทำงานต่อวัน (กม.)",
    distanceWithKm: "ระยะทาง (กิโลเมตร)",
    DistfromlastmaintenanceReport: "พาหนะที่มีระยะทางวิ่งสะสมล่าสุด",
    doc_no: "เลขเอกสาร",
    document: "เอกสาร",
    done: "เสร็จสิ้น",
    doNotInstall: "ยังไม่ติดตั้ง",
    doNotParkBesideTheRoad: "ห้ามจอดไหล่ทาง",
    doNotSwipeCardsOrDamageCards: "ไม่รูดบัตรหรือบัตรเสียหายหนัก",
    download: "ดาวน์โหลด",
    downloadcertificate: "ดาวน์โหลดใบรับรอง",
    doYouNeedToRebootTheseItems: "คุณต้องการรีบูตรายการเหล่านี้หรือไม่",
    doYouWantToAddTheseItems: "คุณต้องการเพิ่มรายการเหล่านี้หรือไม่",
    doYouWantToCancel: "คุณต้องการยกเลิกหรือไม่",
    doYouWantToConfirmSend: "คุณต้องการยืนยันการส่งหรือไม่",
    doYouWantToDeleteTheseItems: "คุณต้องการลบรายการเหล่านี้หรือไม่",
    doYouWantToEditTheseItems: "คุณต้องการเเก้ไขรายการเหล่านี้หรือไม่",
    doYouWanttoReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
      "คุณต้องการส่งอีเมลไปยังผู้ใช้งานเพื่อยืนยันตัวตนหรือไม่",
    DozeoffriskReport: "เสี่ยงหลับใน",
    draft: "ดราฟต์",
    draw: "วาด",
    driveContinuouslyForMoreThan10HoursDay: "ปฏิบัติงาน > 10 ชม./วัน",
    driveContinuouslyForMoreThan4Hours: "ขับต่อเนื่อง > 4 ชม.",
    driveOverTheSpeedLimitInTheCrossroads: "ขับขี่ความเร็วเกินกำหนด ในทางแยก",
    driver: "พนักงานขับขี่",
    driver1: "พนักงานขับขี่ 1",
    driver2: "พนักงานขับขี่ 2",
    driverCode: "รหัสพนักงานขับขี่",
    driverHistory: "ประวัติพนักงานขับขี่",
    DriverinformationReport: "ข้อมูลพนักงานขับขี่",
    driverName: "ชื่อพนักงานขับขี่",
    driverPhoneNumber: "เบอร์โทรพนักงานขับขี่",
    driveTooFastInTheCommunity: "ขับเร็วเกินกำหนด ในเขตชุมชน",
    driveTooFastInTheNormalRoute: "ขับขี่เร็วเกินกำหนด ในเส้นทางปกติ",
    driving: "ขับพาหนะ",
    DrivingdistanceReport: "ระยะทางการวิ่ง",
    DrivinghourReport: "พฤติกรรมการขับขี่",
    drivingIiceneType: "ประเภทใบขับขี่",
    drivingIicenseNumber: "หมายเลขใบขับขี่",
    drivinglicense: "ใบอนุญาตขับขี่",
    drivinglicenseexpire: "ใบอนุญาตขับขี่หมดอายุ",
    drivingLicenseNumberMustBeNumber: "หมายเลขใบขับขี่ต้องเป็นตัวเลข",
    drivingLicenseNumberMustBeNumberandAlphabet:
      "หมายเลขใบขับขี่ต้องเป็นตัวเลขและตัวอักษร",
    DrivingLicenseExpireReport: "รายงานใบขับขี่หมดอายุ",
    DrivinglicensescanlogReport: "การรูดใบขับขี่",
    DrivinglogReport: "บันทึกการขับขี่",
    DrivinglogReport2: "บันทึกการขับขี่ 2",
    DrivinglogspecialReport: "บันทึกการขับขี่ (ใหม่)",
    DrivingoverlimitReport: "พาหนะวิ่งงานเกินเวลาที่กำหนด",
    drivingPeriodRange: "ช่วงระยะเวลาการขับขี่(นาที)",
    drivingTime: "เวลาการขับขี่",
    drumCapacity: "ความจุโม่",
    duration: "ระยะเวลา",
    durationMin: "ระยะเวลา(ชั่วโมง:นาที)",
    duration_time: "ช่วงเวลาของข้อมูล",
    durationOfPTOGate1On: " ระยะเวลาเปิด PTO/ประตู1",
    mdvrdownloadfile: "ดาวน์โหลดวีดีโอ MDVR",
    drivingcontinuous: "รายงานขับรถต่อเนื่องเกิน 4 ชั่วโมง",
    dataType: "ประเภทข้อมูล",
    driverincome: "สรุปค่าเที่ยว",
    driverLicenseExpireDate: "วันที่ใบขับขี่หมดอายุ",
    displaySetting: "ตั้งค่าการแสดงผล",
    defaultPage: "จำนวนหน้า",
    dataSetting: "ตั้งค่าข้อมูล",
    dataFreqEngineOn: "ความถี่ในการส่งข้อมูลตอนติดเครื่อง",
    dataFreqEngineOff: "ความถี่ในการส่งข้อมูลตอนดับเครื่อง",
    deviceConfig: "ตั้งค่าอุปกรณ์",
    db_connect_status: "สถานะเชื่อมต่อฐานข้อมูล",
    driver_car: "",
    depositfuelcredit: "จำนวนฝากเครดิต(ลิตร)",
    depositfuelcreditreasonname: "เหตุผลในการเพิ่ม",
    deviceCode: "รหัสอุปกรณ์",
    dateTimeSnapshot: "วัน/เวลา รูปภาพ",
  },
  // device_config required
  dcRequired: {
    video_type: "กรุณาระบุลักษณะการถ่ายวิดีโอ",
    video_receive_size: "กรุณาระบุขนาดพื้นที่ในการจัดเก็บ(GB)",
    image_resolution: "กรุณาระบุความละเอียดของภาพถ่าย",
    image_position: "กรุณาระบุตำแหน่งการถ่ายภาพ",
    image_update_freq: "กรุณาระบุความถี่ในการอัปเดต(นาที)",
    keep_image: "กรุณาระบุระยะเวลาในการเก็บภาพถ่าย",
    incoming_call_response: "กรุณาระบุลักษณะการตอบรับ(กรณีโทรเข้า)",
    incoming_call_response_auto_type: "กรุณาระบุประเภทการตอบรับอัตโนมัติ",
    emergency_telephone_number: "กรุณาระบุเบอร์โทรติดต่อให้ถูกต้อง",
    numberOnly: "กรุณาระบุตัวเลขเท่านั้น",
    updateSuccess: "เพิ่มข้อมูลสำเร็จ",
    updateFailed: "มีข้อผิดพลาด เพิ่มข้อมูลไม่สำเร็จ",
    icingChannelSelect: "สามารถเลือกได้เมื่อเปิด channel ก่อนหน้าแล้วเท่านั้น",
  },
  e: {
    electricityUse: "การใช้ไฟ",
    export: "ส่งออกข้อมูล",
    EstimatedTime: "เวลาทั้งหมด",
    error: "ล้มเหลว",
    editTable: "แก้ไขตาราง",
    earning: "Earning",
    earningOverview: "ภาพรวมรายได้",
    EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
    earningType: "Earning Type",
    edit: "เเก้ไข",
    editPassword: "เเก้ไขรหัสผ่าน",
    editStatus: "สถานะ",
    efficiency_report_menu: "ประสิทธิภาพ",
    email: "อีเมล",
    email_invalid: "อีเมลที่กรอกไม่ถูกต้อง",
    email_require: "กรุณากรอกอีเมล",
    email_subject: "หัวข้ออีเมล",
    email_subject_require: "กรุณากรอกหัวข้ออีเมล",
    emailAE: "อีเมล AE",
    emailIsInvalid: " อีเมลไม่ถูกต้อง",
    emailVerification: "รอการยืนยันอีเมล",
    emailVerificationStatus: "สถานะการยืนยันอีเมล",
    emailVerificationSuccessful: "ยืนยันอีเมลแล้ว",
    emergencyCase: "SOS",
    emergencyStatus: "สถานะฉุกเฉิน",
    employeeID: "รหัสพนักงาน",
    employeeInformation: "ข้อมูลพนักงาน",
    endAt: "เวลาสิ้นสุด",
    endDate: "วันสิ้นสุด",
    endDestinationCode: "รหัสปลายทาง",
    endDestinationName: "ชื่อปลายทาง",
    endLocationCode: "รหัสปลายทาง",
    endLocationName: "ชื่อปลายทาง",
    endValidDate: "วันที่สิ้นสุดการใช้พาหนะ",
    energy_report_menu: "พลังงาน",
    engine: "เครื่องยนต์",
    engine_off_time_end_at: "เวลาดับเครื่องสิ้นสุด",
    engine_off_time_start_at: "เวลาดับเครื่องเริ่มต้น",
    engine_on_time_end_at: "เวลาติดเครื่องสิ้นสุด",
    engine_on_time_start_at: "เวลาติดเครื่องเริ่มต้น",
    engineOff: "ดับเครื่อง",
    engineOffDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะดับเครื่อง",
    engineOn: "ติดเครื่อง",
    engineOnDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะติดเครื่อง",
    engineOnDuration: "ระยะเวลาติดเครื่องยนต์",
    engineOnWithImg: "ติดเครื่องพร้อมข้อมูลรูปภาพ",
    engineOnWithImgDesc:
      "ตำแหน่งที่ส่งข้อมูล GPS พร้อมรูปภาพจาก Dash camera ขณะติดเครื่อง",
    engineStatus: "สถานะเครื่องยนต์",
    estFare: "ค่าโดยสาร",
    estTransitDistance: "ระยะทางการเดินทาง",
    estTransitDuration: "ระยะเวลาการเดินทาง",
    etc: "อื่นๆ",
    europeAmerican: "ยุโรป/อเมริกัน",
    event: "เหตุการณ์",
    eventGraph: "กราฟสรุปเหตุการณ์",
    eventGraph2: "กราฟสรุปข้อมูลความเร็ว เชื้อเพลิง อุณหภูมิ จากข้อมูล GPS",
    eventAlarm: "Event Alarm",
    eventEnd: "จบเหตุการณ์",
    eventMap: "ภาพรวมพิกัดการฝ่าฝืน",
    EventReport: "เหตุการณ์พาหนะรายคัน",
    excel: "Excel",
    expand: "ขยาย",
    expense: "ค่าใช้จ่าย",
    expenseBaht: "ค่าใช้จ่าย (บาท)",
    expired: "หมดอายุแล้ว",
    expirein1month: "ใกล้หมดอายุ(1 เดือน)",
    expirein3month: "ใกล้หมดอายุ(3 เดือน)",
    expireDate: "วันที่หมดอายุ",
    expiryDate: "วันหมดอายุ",
    export_date_at: "วันที่ออก",
    expire_document_status: "เอกสารใกล้หมดอายุ",
    extra: "เพิ่มเติม",
    getVehicles: "แสดงพาหนะ",
    emergencyCall: "เบอร์โทรฉุกเฉิน(กรณีโทรออก)",
    emergencyCallReserve: "เบอร์โทรฉุกเฉินสำรอง(กรณีโทรออก)",
    extendCameraSetting: "ตั้งค่ากล้อง",
    engine_on_only: "เฉพาะตอนติดเครื่อง",
    endTime: "เวลาสิ้นสุด",
    expireperiod: "ช่วงเวลาหมดอายุ",
  },
  extraSetting: {
    extraSetting: "ตั้งค่าเพิ่มเติม",
    min: "(ต่ำ)",
    turn_left: "เลี้ยวซ้าย",
    turn_right: "เลี้ยวขวา",
    max: "(สูง)",
    duration: "ระยะเวลา(วินาที)",
    horn: "แตร",
    lowLight: "ไฟต่ำ",
    highLight: "ไฟสูง",
    backupLight: "ไฟถอย",
    breakLight: "ไฟเบรก",
    wiper: "ที่ปัดน้ำฝน",
    mute: "ปิดเสียง",
  },
  event: {
    acc: "ความเร่งผิดปกติ",
    approaching_point: "ใกล้ถึงจุดหมาย",
    approaching_point_but_late: "รถใกล้ถึงแต่สาย",
    arrival_but_late_departure: "รถอยู่ที่หมายแต่จะออกจากจุดสาย",
    arrival_point: "รถอยู่ที่หมาย",
    blackbox_restart: "กล่องรีสตาท",
    boom_ready: "ยกบูม",
    discharge_concrete: "หมุนเทปูน",
    driver_rest_lower_than_10_hours: "Driver rest lower than 10 hours",
    engineoff: "ดับเครื่องยนต์",
    engineon: "ติดเครื่องยนต์",
    fuel_anomaly: "ปริมาณน้ำมันผิดปกติ",
    hard_brake: "เบรคแรง",
    hard_turn: "เข้าโค้งแรง",
    high_beam_off: "ปิดไฟสูง",
    high_beam_on: "เปิดไฟสูง",
    high_rpm: "รอบเครื่องสูงเกินกำหนด",
    hit_the_brake: "เหยียบเบรค",
    honk: "บีบแตร",
    into_and_out_of_specific_areas: "เข้าออกพื้นที่เฉพาะ",
    late: "ถึงจุดหมายสาย",
    long_idle_time: "ติดเครื่องอยู่กับที่",
    low_beam_off: "ปิดไฟต่ำ",
    low_beam_on: "เปิดไฟต่ำ",
    low_speed_more_than_10_minute: "วิ่งความเร็วต่ำต่อเนื่องมากกว่า 10 นาที",
    mixing_concrete: "หมุนโหลดปูน",
    move_in_point_late: "Move in point late",
    move_out_load_point_with_out_ticket: "ออกจากแพล้นโดยไม่มีตั๋ว",
    move_out_point_late: "Move out point late",
    off_route: "ออกนอกเส้นทาง",
    open_door: "เปิดตู้",
    open_door_outside_point: "เปิดตู้นอกจุดส่ง",
    over_driving_hour_limit: "วิ่งต่อเนื่องเกินกำหนด",
    poweroff: "ปิดเครื่องส่งสัญญาณ",
    poweron: "เปิดเครื่องส่งสัญญาณ",
    pto_off: "ปิด PTO",
    pto_on: "เปิด PTO",
    pump_on: "เปิดปั๊ม",
    refuel_full: "เติมน้ำมันเต็มถัง",
    refuel_not_full: "เติมน้ำมันไม่เต็มถัง",
    release_the_brake: "หยุดเหยียบเบรค",
    reversing_light_off: "ปิดไฟถอยหลัง",
    reversing_light_on: "เปิดไฟถอยหลัง",
    shock: "สะเทือน",
    speed_anomaly: "ความเร็วผิดปกติ",
    stop_honk: "หยุดบีบแตร",
    stop_in_area: "จอดรถในพื้นที่ที่กำหนด",
    stop_mixing_concrete: "โม่หยุดหมุน",
    summary_idle_limit: "สรุปรถติดเครื่องอยู่กับที่",
    summary_low_speed_more_than_10_minute:
      "สรุปวิ่งความเร็วต่ำต่อเนื่องมากกว่า 10 นาที",
    summary_over_driving_hour_limit: "สรุปวิ่งต่อเนื่องเกินกำหนด",
    temperature_anomaly: "อุณหภูมิผิดปกติ",
    truck_stay_in_plant_more_than_limit: "Truck stay in plant more than limit",
    turn_signal_off: "ปิดไฟเลี้ยว",
    turn_signal_on: "เปิดไฟเลี้ยว",
    turn_left_signal_on: "เปิดไฟเลี้ยวซ้าย",
    turn_left_signal_off: "ปิดไฟเลี้ยวซ้าย",
    turn_right_signal_on: "เปิดไฟเลี้ยวขวา",
    turn_right_signal_off: "ปิดไฟเลี้ยวขวา",
    unauthorized_discharge: "เทปูนนอกไซต์งาน",
    windshield_wipers_off: "ปิดที่ปัดน้ำฝน",
    windshield_wipers_on: "เปิดที่ปัดน้ำฝน",
    work_longer_than_the_time_limit: "Work longer than the time limit",
    working_distance_less_than_the_rule: "Working distance less than the rule",
    working_over_limit: "Working over limit",
    working_within_a_given_period: "Working within a given period",
  },
  f: {
    fuelUsage: "เชื้อเพลิงที่ใช้",
    firstLogOn: "Logon ครั้งแรก",
    finishQueue: "ดาวน์โหลดเสร็จแล้ว",
    filterSearch: "กรองค้นหา",
    findCarThisArea: "ค้นหารถบริเวณนี้",
    facilityName: "ชื่อสถานที่",
    facilityName2: "ชื่อสถานที่2",
    facilityName3: "ชื่อสถานที่3",
    fail: "ไม่สำเร็จ",
    fare: "ค่าโดยสาร",
    file: "ไฟล์",
    file_location: "สถานที่เก็บไฟล์",
    file_size: "ขนาดไฟล์",
    file_size_small: "ไฟล์ขนาดเล็ก",
    file_size_large: "ไฟล์ขนาดใหญ่",
    fileapetition: "ยื่นคำร้อง",
    filenotreadydownload: "ไม่สามารถดาวน์โหลดไฟล์ได้ในขณะนี้",
    fileName: "ชื่อไฟล์",
    fillInfomation: "กรอกข้อมูล",
    fillInForm: " เติมข้อมูลลงในฟอร์ม",
    fillInFrom: "Fill In From",
    fillPosition: "ป้อนตำแหน่ง",
    filter: "กรอง",
    filter_by: "กรองข้อมูลโดย",
    filter_type: "รูปแบบ",
    filter_type1: "ออกจากโรงงานครั้งแรกถึงเข้าโรงงานครั้งสุดท้าย",
    filter_type2: "ติดเครื่องครั้งแรกถึงดับเครื่องครั้งสุดท้าย",
    findCustomer: "ค้นหาลูกค้า",
    findDistance: "หาระยะทาง",
    findSpace: "หาพื้นที่",
    fine: "ปรับ",
    finish: "เสร็จสิ้น",
    finished: "เสร็จสิ้น",
    finishRefuel: "จบการเติมเชื้อเพลิง",
    firmware: "เฟิร์มเเวร์",
    firmwareNumber: "เลขเฟิร์มเเวร์",
    firmwareVersion: "เวอร์ชันเฟิร์มแวร์",
    first_dest: "จุดเริ่มต้น",
    firstMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งแรก",
    firstName: "ชื่อ",
    firstNameisCharacters: "ชื่อต้องเป็นตัวอักษร",
    fixed: "ซ่อม",
    flateNumber: "Flate Number",
    flatRate: "เหมา",
    forceUpdate: "บังคับอัปเดต",
    forgot_password: "ลืมรหัสผ่าน",
    front: "กล้องนอกรถ",
    fuel: "เชื้อเพลิง",
    fuel1: "ค่าน้ำมัน 1",
    fuel2: "ค่าน้ำมัน 2",
    fuelA: "Fuel A",
    fuelB: "Fuel B",
    fuelC: "Fuel C",
    fuelFull: "น้ำมันเต็มถัง",
    fuelRate: "Fuel Rate",
    fuelremainrateReport: "น้ำมันคงเหลือ",
    fuelNotifyReport: "แจ้งเตือนน้ำมัน",
    fuel_consumption: "ปริมาณการใช้น้ำมันสะสม(ลิตร)",
    fuelTankSize: "ความจุถังเชื้อเพลิง",
    fuelType: "ประเภทเชื้อเพลิง",
    fuelUsage: "อัตราสิ้นเปลืองเชื้อเพลิง",
    fullScreen: "ขยายเต็มจอ",
    Fullscreen: " ขยายเต็มจอ",
    fullScreenExit: "ออกจากโหมดเต็มจอ",
    fullTank: "เต็มถัง",
    fueltank: "ค่าความจุถังที่ต้องการ(ลิตร)",
    fueltankRequire: "กรุณากรอกค่าความจุถังที่ต้องการ(ลิตร)",
    fueladd: "ค่าน้ำมันที่ต้องเติม(ลิตร)",
    fueladdRequire: "กรุณากรอกค่าน้ำมันที่ต้องเติม(ลิตร)",
    fleetCard: "ข้อมูล fleet card",
    fleetCardCode: "หมายเลข fleet card",
    fuelCreditForm: "แบบฟอร์มเพิ่มเครดิต",
    filterLocationType: "กรองตามประเภทสถานที่",
    fileSize: "ขนาดไฟล์",
  },
  g: {
    Graph: "กราฟ",
    gallery: "แกลเลอรี่",
    gallery_name: "ชื่อ",
    gate1: "ประตู 1",
    gate2: "ประตู 2",
    gate3: "ประตู 3",
    general: "ทั่วไป",
    general_report_menu: "ทั่วไป",
    generalLocation: "General Location",
    generateMap: "สร้างแผนที่",
    goBackToUseImagesByLocationType: "กลับไปใช้รูปภาพตามประเภทสถานที่",
    googleMapApiKey: "Google Map Api Key",
    gps_update: "ตำแหน่งล่าสุด",
    gpsCertification: "ใบรับรองการติดตั้ง",
    gpsCertRequest: "ขอใบรับรองการติดตั้ง (EZY GPS)",
    gpsCertRequestHistory: "ประวัติการขอใบรับรองการติดตั้ง (EZY GPS)",
    gpsNotFixed: "GPS ไม่เกาะ",
    gpsNotFixedDesc: "พิกัดไม่แม่นยำ อาจทำให้ตำแหน่งคลาดเคลื่อนจากความเป็นจริง",
    GpsstatusReport: "พาหนะที่สัญญาณขาดหาย",
    gradeAtoF: "เกรด (A ถึง F)",
    greater_than: "มากกว่า",
    greaterOrEqual: "มากกว่าเท่ากับ",
    group: "กลุ่ม",
    group_by: "จัดกลุ่มโดย",
    group_data: "Group ข้อมูล",
    group_idle: "เฉพาะจอดติดเครื่อง",
    group_speed: "เฉพาะความเร็วเกิน",
    groupByLocation: "จัดกลุ่มตามสถานที่",
    groupVehicleType: "กลุ่ม/ประเภทพาหนะ",
    googleSetting: "ตั้งค่า Google API",
    graphsonsors: "กราฟแสดงค่าเซนเซอร์",
    gpsIsFixed: "GPS ที่ไม่น่าเชื่อถือ",
    gpsTime: "เวลาติดเครื่อง",
    gpsPerformanceDesGPS:
      'คิดจากการใช้งานได้ในช่วงเวลาที่วิ่งงานแต่ละตั๋ว โดยหักเวลาก่อนแจ้งซ่อมออก ในกรณีที่ขาดการติดต่อไม่สามารถจับเวลาการทำงานได้ จะคิดเวลาการทำงานแต่ละตั๋วตั้งแต่เวลาผลิตเสร็จ + 3 ชม.',
    gpsActive: "GPS ทำงาน",
    gpsNotActive: "GPS ไม่ทำงาน",
  },
  h: {
    hardwareID: "รหัสฮาร์ดแวร์",
    header: "หัวข้อ",
    height: "สูง",
    help_menu: "ช่วยเหลือ",
    high: "สูง",
    highRPM: "High RPM",
    history_servicerepairdevice: "ประวัติ",
    home: "หน้าหลัก",
    honeytoastdownloadfile: "ดาวน์โหลดไฟล์วิดีโอ",
    honeytoastlivestream: "วีดีโอเรียลไทม์จากฮันนีโทสท์",
    honeytoastplayback: "วีดีโอย้อนหลัง",
    horsePower: "เเรงม้า",
    hotel: "โรงเเรม",
    hour: "ชั่วโมง",
    hours: "ชม.",
    hotspot: "ฮอตสปอต",
    heightMain: "ความสูงหลัก",
    heightSub: "ความสูงรอง",
  },
  i: {
    iconDesc: "รายละเอียดไอคอน",
    id: "ID",
    idcard: "เลขบัตรประชาชน",
    idleFuelRate: "Idle Fuel Rate",
    ignoredAlert: "ไม่แจ้งเตือน",
    imageFromDashCamera: "ภาพจากกล้อง",
    imageFromTheGallery: "รูปจากแกลเลอรี่",
    imageMustSmallerThan5MB: "รูปภาพต้องมีขนาดเล็กกว่า 5MB",
    imagesNotUpdate: "รูปภาพไม่อัปเดต",
    immediatelyAcess: "ทันที",
    import: "นำเข้าข้อมูล",
    include_engine_off: "รวมข้อมูลดับเครื่อง",
    indexFilterOption_All: "ทั้งหมด",
    indexFilterOption_Cancel: "ยกเลิก",
    indexFilterOption_Confirm: "ยืนยัน",
    indexFilterOption_Finish: "เสร็จสิ้น",
    indexFilterOption_Unassign: "ยังไม่มีพนักงานขับขี่",
    indexFilterOption_Waiting: "รอพนักงานขับขี่ยืนยัน",
    indian: "อินเดีย",
    inEvery: "ในทุกๆ",
    infomation: "ข้อมูล",
    inoutlocationreport: "รายงานการเข้าออกสถานที่",
    inoutlocation: "การเข้าออกสถานที่",
    inside: "ด้านใน",
    insidePolicy: "ภายในสถานที่",
    install: "ติดตั้ง",
    install_at: "ติดตั้งเมื่อ",
    installDevice: "ติดตั้งอุปกรณ์",
    installed: "ติดตั้งเเล้ว",
    installStatus: "สถานะการติดตั้ง",
    insurance: "ประกันภัยรถยนต์",
    insuranceno: "หมายเลขกรมธรรม์ประกันภัยรถยนต์",
    insurance_report_menu: "ประกันภัยพาหนะ",
    insuranceID: "หมายเลขกรมธรรม์",
    InsuranceReport: "ประกันภัยพาหนะ",
    insure_report_menu: "ประกันภัย",
    internalOperation: "การจัดการภายใน",
    invoice_date: "วันที่ใบส่งของ",
    invoice_number: "เลขที่ใบส่งของ",
    issuedDate: "วันที่ออก",
    itemLeft: "เหลือ",
    itemSelected: "เลือก",
    incident: "แดชบอร์ดเหตุการณ์",
    incidentShow: "แสดงในหน้าแดชบอร์ดเหตุการณ์",
    incidentReport: "รายงาน Incident",
    imageResolution: "ความละเอียดภาพถ่าย",
    imagePosition: "ตำแหน่งภาพถ่าย",
    imageUpdateFreq: "ความถี่ในการอัปเดต(นาที)",
    imageKeep: "ระยะเวลาในการเก็บภาพถ่าย(วัน)",
    incomingCall: "ลักษณะการตอบรับ(กรณีโทรเข้า)",
    incomingCallAuto: "ประเภทการตอบรับอัตโนมัติ",
    installCameraInsideCar: "ติดตั้งกล้องด้านในรถ",
    insideCameraPassword: "รหัสผ่าน",
    imageSetting: "ตั้งค่าส่วนภาพถ่าย",
    image_must_smaller_than_512: "รูปภาพต้องมีขนาดเล็กกว่า 512 KB!",
    insideCameraType: "ประเภทกล้องด้านในรถ",
    insideCameraUser: "ชื่อผู้ใช้งาน",
    insideCameraPath: "พาธ",
    insideCameraPort: "พอร์ต",
    InputactivityReport: "การเปิด PTO/ประตู",
    insufficientCredit: "เครดิตไม่เพียงพอ",
    insuranceAndTaxes: "ประกันภัย/ภาษี",
    insideCameraModel: "รุ่นกล้องด้านในรถ",
  },
  j: {
    jobDetail: "รายละเอียดงาน",
    jobId: "รหัสงาน",
    jobStartAt: "เวลาเข้างาน",
    jobStatus: "สถานะงาน",
    jobType: "ประเภทงาน",
    jobTypeSTATISTICS: "สถิติประเภทของงาน",
    joinDate: "วันที่เริ่มงาน",
    job_order_name: "ใบสั่งงาน",
  },
  k: {
    keywordAtleast3character: "คำหลักอย่างน้อย 2 ตัวอักษร",
    kind: "ชนิด",
    km: "กม.",
    kmH: "กม./ชม.",
    kmHrS: "กม./ชั่วโมง ภายใน 1 วินาที",
    kpiDateTime: "วันที่/เวลา",
    kpiEvent: "เหตุการณ์",
  },
  l: {
    lastDrive: "วันที่ขับล่าสุด",
    lastUpdated: "อัพเดตล่าสุด",
    latestLogoff: "Logoff ล่าสุด",
    linkDownload: "ลิงค์ดาวน์โหลด",
    lastest_signal: "สัญญาณล่าสุด",
    lastmaintenanceAt: "การซ่อมบำรุงครั้งล่าสุด",
    lastName: "นามสกุล",
    LastNameisCharacters: "นามสกุลต้องเป็นตัวอักษร",
    lastRequestOn: "คำขอล่าสุดเมื่อ",
    lat: "ความเร่งด้านข้าง",
    late: "ที่สาย",
    latePoint: "จุดที่เข้าสาย ค่าเกณฑ์",
    latestDriver: "พนักงานขับขี่ล่าสุด",
    latitude: "ละติจูด",
    latitudeIsRequired: "กรุณากรอกละติจูด",
    latitudeIsRequiredAndCantBeEmpty: "กรุณากรอกละติจูด",
    lazada: "ลาซาด้า",
    length: "ความยาว",
    less_than: "น้อยกว่า",
    lessOrEqual: "น้อยกว่าเท่ากับ",
    licenseStatus: "สถานะใบขับขี่",
    lightStatus: "สถานะไฟ",
    limit10Driver: "จำนวนพนักงานขับขี่ที่เลือกต้องไม่เกิน 10 คน",
    limitAggesiveAccelerationThreshold: "เร่งแรงได้ไม่เกิน",
    limitDrivingAggesiveThreshold: "ขับพาหนะจี้ท้ายได้ไม่เกิน",
    limitDrivingHourTime: "จำกัดระยะเวลาวิ่งงานต่อเนื่อง (นาที)",
    limitGoInsideArea: "ห้ามเข้าพื้นที่เกิน",
    limitGoOutsideArea: "ห้ามออกพื้นที่เกิน",
    limitOpenDuration: "ห้ามเปิดเกิน",
    limitParking: "ห้ามจอดเกิน",
    limitParkingDuration: "ห้ามจอดเกิน",
    limitParkingInsideLocation: "ห้ามจอดในสถานที่เกิน",
    limitParkingOutsideLocation: "ห้ามจอดนอกสถานที่เกิน",
    limitSnoozeThreshold: "สุ่มเสี่ยงหลับในได้ไม่เกิน",
    limitSuddenlyBreakThreshold: "เบรคแรงได้ไม่เกิน",
    limitUsingPhoneThreshold: "ใช้โทรศัพท์ขณะขับขี่ได้ไม่เกิน",
    link: "ลิงก์",
    liveStream: "วีดีโอเรียลไทม์",
    load: "โหลด",
    loadAmount: "จำนวนโหลด",
    loadpoint: "จุดโหลดของ",
    loadStartAt: "โหลดเมื่อ",
    location: "สถานที่",
    Location: "สถานที่",
    locationAndRoute: "สถานที่และเส้นทาง",
    locationCode: "รหัสสถานที่",
    locationCodeIsRequired: "กรุณากรอกรหัสสถานที่",
    locationGroup: "กลุ่มสถานที่",
    locationGroupName: "ชื่อกลุ่มสถานที่",
    locationGroupNameIsRequired: "กรุณากรอกชื่อกลุ่มสถานที่",
    locationGroupTypeIsRequired: "กรุณาเลือกประเภทกลุ่มสถานที่",
    locationInformation: "ข้อมูลสถานที่",
    LocationInSystem: "สถานที่ที่มีอยู่ในระบบ",
    locationName1IsRequired: "กรุณากรอกชื่อสถานที่",
    LocationOfDeviceUser: "สถานที่ต้นสังกัดของพนักงานขับขี่",
    LocationOfVehicle: "สถานที่ต้นสังกัดของพาหนะ",
    locationShareFrom: "สถานที่ที่ถูกเเชร์",
    locationShareTo: "สถานที่ที่เเชร์",
    locationType: "ประเภทสถานที่",
    location_type: "ชนิดสถานที่",
    locationTypeName: "ชื่อชนิดสถานที่",
    locationTypeNameIsRequired: "กรุณากรอกชื่อชนิดสถานที่",
    login: "เข้าสู่ระบบ",
    login_fail: "เข้าระบบไม่สำเร็จ",
    login_success: "เข้าระบบสำเร็จ",
    LoginhistoryReport: "ประวัติการเข้าใช้งานระบบรายคน",
    logType: "ชนิดของบันทึก",
    logout: "ออกจากระบบ",
    longdoMapApiKey: "Longdo Map Api Key",
    longitude: "ลองจิจูด",
    longitudeIsRequired: "กรุณากรอกลองจิจูด",
    LongitudeIsRequiredAndCantBeEmpty: "กรุณากรอกลองจิจูด",
    lookingForSomething: "ป้อนตำแหน่ง",
    lostConnectio: "ขาดการติดต่อ",
    low: "ต่ำ",
    lowQualityVideo: "วิดีโอคุณภาพต่ำ",
    loading: "กำลังโหลดข้อมูล",
    latestConnection: "เชื่อมต่อสัญญาณล่าสุด",
  },
  locationType: {
    "-": "-",
    All: "ทั้งหมด",
    BS: "ป้ายรถเมล์หลัก",
    BS1: "ป้ายรถเมล์ 1",
    BS2: "ป้ายรถเมล์ 2",
    CarService: "ศูนย์บริการ",
    GasStation: "ปั้มน้ำมัน",
    NoParking: "จุดห้ามจอด",
    OnSite: "แพลนท์ออนไซต์",
    ParkingLot: "ลานจอดพาหนะ",
    Plant: "จุดโหลดของ",
    PreparePoint: "จุดเตรียมความพร้อม",
    RestArea: "จุดพักพาหนะ",
    Site: "จุดส่งของ",
    Temporary: "สถานที่ชั่วคราว",
    StaffPickUpAndDropOffPoint: "จุดรับ-ส่งพนักงาน",
    ManyParking: "สถานที่ > 1",
    DefaultLocation: "สถานที่เริ่มต้น",
    InformalLocation: "สถานที่นอกระบบ",
  },
  m: {
    min_temp: "อุณหภูมิต่ำสุด",
    max_temp: "อุณหภูมิสูงสุด",
    moveToQueue: "ย้ายไปยังคิว",
    m: "เมตร",
    ms2: "เมตร/วินาที²",
    m_april: "เมษายน",
    m_august: "สิงหาคม",
    m_december: "ธันวาคม",
    m_february: "กุมภาพันธ์",
    m_january: "มกราคม",
    m_july: "กรกฎาคม",
    m_june: "มิถุนายน",
    m_march: "มีนาคม",
    m_may: "พฤษภาคม",
    m_november: "พฤศจิกายน",
    m_october: "ตุลาคม",
    m_september: "กันยายน",
    machineSerialNumber: "หมายเลขเครื่องยนต์",
    maintenance: "การบำรุงรักษา",
    maintenance_status: "สถานะ",
    maintenance_type: "ประเภทการแจ้ง",
    maintenanceConditions: "เงื่อนไขการซ่อมบำรุง",
    maintenancedevice_report_menu: "การจัดการอุปกรณ์",
    MaintenancedeviceconcludeReport: "การซ่อม/ถอดอุปกรณ์",
    MaintenanceReport: "พาหนะที่ต้องบำรุงรักษา",
    manage_product: "จัดการสินค้า",
    management_menu: "การจัดการ",
    managementdevice_report_menu: "การจัดการอุปกรณ์",
    map: "แผนที่",
    mapType: "Map Type",
    mapView: "มุมมองเเผนที่",
    masterData: "ข้อมูลหลัก",
    match: "จับคู่",
    max_speed: "ความเร็วสูงสุด",
    maxDrumCapacity: "ความจุโม่สูงสุด",
    medium: "ปานกลาง",
    message: "ข้อความ",
    meter: "มิเตอร์",
    meterDashCameras: "มิเตอร์",
    meterSN: "Meter S/N",
    meterStatus: "สถานะ Meter",
    mileNo: "เลขไมล์",
    min: "นาที",
    min_speed: "ความเร็วสูงสุด(ขั้นต่ำ)",
    Minimal: "อย่างย่อ",
    minute: "นาที",
    miss: "นางสาว",
    mixingDrum: "โม่ผสมปูน",
    model: "รุ่น",
    moisture: "ความชื้น",
    monitoring: "ระบบติดตาม",
    month: "เดือน",
    monthly: "รายเดือน",
    more_1_min: "มากกว่า 1 นาที",
    more_10_mins: "มากกว่า 10 นาที",
    more_10_sec: "มากกว่า 10 วินาที",
    more_30_sec: "มากกว่า 30 วินาที",
    more_5_mins: "มากกว่า 5 นาที",
    more_5_sec: "มากกว่า 5 วินาที",
    more_60_sec: "มากกว่า 60 วินาที",
    move: "ย้าย",
    move_in: "เข้าจุด",
    move_out: "ออกจุด",
    moveDevice: "เเจ้งย้ายอุปกรณ์",
    mr: "นาย",
    mrs: "นาง",
    multiDropDescIncomingTrip: "กำลังเข้าจุด",
    multiDropDescIncomingTripDesc: "พาหนะกำลังเดินทางไปยังจุดหมายที่กำหนด",
    multiDropDescIncomingLateTrip: "กำลังเข้าจุด(สาย)",
    multiDropDescIncomingLateTripDesc:
      "พาหนะกำลังเดินทางไปยังจุดหมายที่กำหนด และล่าช้ากว่าเวลาแผน",
    multiDropDescAlreadyVisitTrip: "เข้าจุด",
    multiDropDescAlreadyVisitTripDesc: "พาหนะถึงจุดหมาย (ตรงเวลา)",

    multiDropDescVisited: "ยังไม่เข้าจุด",
    multiDropDescVisitedDesc: "พาหนะยังไม่ถึงจุดหมาย",
    multiDropDescVisitedConfirm: "รอยืนยัน",
    multiDropDescVisitedConfirmDesc:
      "พาหนะถึงจุดหมายและอยู่ระหว่างยืนยันการเข้าจุด (อยู่ในสถานที่ 3 นาที)",
    multiDropDescVisitedLate: "เข้าจุดสาย",
    multiDropDescVisitedLateDesc: "พาหนะถึงจุดหมายล่าช้ากว่าเวลาแผน",
    multiDropDescVisitedOnTime: "เข้าจุด",
    multiDropDescVisitedOnTimeDesc: "พาหนะถึงจุดหมาย (ตรงเวลา)",
    multiDropDescLeave: "ยังไม่ออกจุด",
    multiDropDescLeaveDesc: "พาหนะยังไม่ออกจากจุดหมาย",
    multiDropDescLeaveConfirm: "รอยืนยัน",
    multiDropDescLeaveConfirmDesc:
      "พาหนะออกจุดหมายและอยู่ระหว่างยืนยันการออกจุด (ออกนอกสถานที่ 3 นาที)",
    multiDropDescLeaveLate: "ออกจุดสาย",
    multiDropDescLeaveLateDesc: "พาหนะออกจุดหมายล่าช้ากว่าเวลาแผน",
    multiDropDescLeaveOnTime: "ออกจุด",
    multiDropDescLeaveOnTimeDesc: "พาหนะออกจุดหมาย (ตรงเวลา)",
    manageDocument: "จัดการเอกสาร",
    manageDocument2: "จัดการเอกสาร",
    minutes: "นาที",
    manageRepairDevice: "จัดการแจ้งซ่อม",
    manageDepositFuelCredit: "จัดการเพิ่มเครดิตน้ำมัน",
    manageWithdrawFuelCredit: "จัดการเบิกเครดิตน้ำมัน",
    monitorSDCard: "ตรวจสอบ SDCard",
    manageTemplate: "จัดการแบบ",
    monitorGPS: 'ติดตาม %GPS',
    monitorGPSLog: 'รายการสถานะ GPS',
  },
  n: {
    notificationTimeBeforeDueDate: "เวลาแจ้งเตือนก่อนถึงกำหนด",
    nope: "ไม่มี",
    nodriver: "ไม่มีชื่อคนขับ",
    name: "ชื่อ",
    nameAE: "ชื่อ AE",
    nameAndType: "ชื่อและประเภท",
    nameFromDrivingID: "ชื่อจากการรูดใบขับขี่",
    nameTableCompany: "ชื่อ",
    nameTitle: "คำนำหน้า",
    nationality: "สัญชาติ",
    nationalityZone: "โซนสัญชาติ",
    nearByLocation: "สถานที่ใกล้เคียง",
    nearbyPlants: "เเพลนต์ใกล้สุด",
    nearPlants: "แพลนต์ใกล้ๆ",
    new: "เพิ่ม",
    newCustomer: "ผู้ใช้บริการใหม่",
    newJob: "งานใหม่",
    newLocation: "สถานที่ใหม่",
    newPassword: "รหัสผ่านใหม่",
    newRoute: "เส้นทางการวิ่งใหม่",
    newTicket: "Ticket ใหม่",
    newUser: "ผู้ใช้งานใหม่",
    newUserGroup: "กลุ่มผู้ใช้บริการใหม่",
    next: "ถัดไป",
    nextMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งต่อไป",
    nextRegisterDate: "วันต่ออายุประกันภัยพาหนะ",
    nightTime: "ช่วงเวลากลางคืน",
    no: "ไม่",
    no_filter: "ไม่กรอง",
    node: "Node",
    nodeName: "ชื่อ Node",
    noEntry: "ห้ามเข้า",
    noExit: "ห้ามออก",
    nofilevideo: "ไม่สามารถสตรีมวีดีโอในเวลาที่เลือกได้",
    none: "ปกติ",
    normalStatusImage: "รูปภาพแสดงสถานะปกติ",
    notAllowParking: "จอดในพื้นที่",
    notAvailable: "ไม่พร้อมใช้งาน",
    notification: "การแจ้งเตือน",
    notify_date: "วันที่แจ้ง",
    notificationEndWhen: "จบแจ้งเตือนเมื่อ",
    notificationPasswordNotSafeInfo:
      "แจ้งเตือนรหัสผ่านไม่ปลอดภัย ผู้ใช้สามารถเปลี่ยนรหัสผ่านด้วยตนเองที่เมนูโปรไฟล์",
    notificationTime: "เวลาที่แจ้งเตือน",
    notificationTracking: "การแจ้งเตือน ติดตามพาหนะ",
    notificationType: "ประเภทการเเจ้งเตือน",
    notReadyForWork: "ไม่พร้อมรับงาน",
    nounsCar: "คัน",
    noVehicleSelect: "ยังไม่เลือกพาหนะ",
    now: "ปัจจุบัน",
    number: "จำนวน",
    number_of_days: "จำนวนวัน",
    numberOfAlarmsPerDay: "จำนวนครั้งที่เกิด Alarm ต่อวัน",
    numberOfCardSwipeTimes: "จำนวนครั้งที่รูดบัตร",
    numberOfContinuousAlarmDay: "จำนวนวันที่เกิด Alarm ต่อเนื่อง",
    NumberofdaysworkedReport: "จำนวนวันที่พาหนะวิ่งงาน",
    numberofEntryLatePoint: "จำนวนจุดที่เข้าช้า (จุด)",
    numberOfExpirationDates: "จำนวนวันที่หมดอายุ",
    numberOfVisitors: "จำนวนผู้เข้าชม",
    numberVehiclegroup: "จำนวนรถที่มีสิทธิ์เห็น (คัน)",
    numberTotalVehiclegroup: "จำนวนรถทั้งหมดในกลุ่ม (คัน)",
    notAllowed: "ไม่อนุญาต",
    notinotswipecard: "รายงานการแจ้งเตือนไม่รูดบัตร",
    notSendToDLT: "ไม่ส่งเข้ากรม",
    noReport: "ลำดับ",
    numberOfTimesDeviceOnline: "จำนวนครั้งที่อุปกรณ์ออนไลน์",
    numberOfTimesEngineStarted: "จำนวนครั้งที่ติดเครื่อง",
  },
  o: {
    obd: "OBD",
    off: "ดับ",
    offFilter: "ดับ",
    offFilterMeter: "ปิด",
    offline: "ออฟไลน์",
    ok: "ตกลง",
    okay: "ตกลง",
    on: "ติด",
    onDevelop: "อยู่ระหว่างการพัฒนา",
    onFilter: "ติด",
    onFilterMeter: "เปิด",
    online: "ออนไลน์",
    onProcess: "อยู่ระหว่างการส่ง",
    open: "เปิด",
    opened: "รับเรื่อง",
    openFilter: "เปิดการค้นหา",
    operation: "การทำงาน",
    operator: "ผู้ประกอบการ",
    or: "หรือ",
    orderCode: "รหัสการสั่งซื้อ",
    ordinal_numbers: "ครั้งที่",
    origin: "ต้นทาง",
    originToDestination: "ต้นทาง-ปลายทาง",
    other: "อื่นๆ",
    outside: "ด้านนอก",
    outofcontact: "แจ้งอุปกรณ์ขาดการติดต่อ",
    over_hour: "พาหนะวิ่งเกิน(ชั่วโมง)",
    over12Month: "ช่วงวันที่ที่เลือกไม่ควรเกิน 12 เดือน",
    over20: "เลือกรถได้ไม่เกิน 20 คัน",
    over2Month: "ช่วงวันที่ที่เลือกไม่ควรเกิน 2 เดือน",
    over30: "เลือกรถได้ไม่เกิน 30 คัน",
    over32Month: "ช่วงวันที่ที่เลือกไม่ควรเกิน 3 ปี",
    over50: "เลือกรถได้ไม่เกิน 50 คัน",
    over100: "เลือกรถได้ไม่เกิน 100 คัน",
    overdueSpeedInTheCommunityArea: "ความเร็วเกินกำหนดในเขตชุมชน",
    overOneMonth: "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 เดือน",
    overSpeedInNormalRoutes: "ความเร็วเกินกำหนดในเส้นทางปกติ",
    overSpeedInTheJunction: "ความเร็วเกินกำหนดในทางเเยก",
    overview: "ภาพรวม",
    ownerView: "เจ้าของพาหนะเห็นข้อมูล",
    vehicleOffline: "พาหนะสถานะออฟไลน์",
    over5Days: "ช่วงวันที่ที่เลือกต้องไม่เกิน 5 วัน",
    over200: "เลือกรถได้ไม่เกิน 200 คัน",
    over1Day: "ช่วงวันที่ที่เลือกไม่เกิน 1 วัน",
    operatorName: "ชื่อผู้ประกอบการ",
    over3Days: "ช่วงวันที่ที่เลือกไม่ควรเกิน 3 วัน",
    over1Month: "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 เดือน",
  },
  p: {
    parkingTime: "เวลาจอดพัก",
    periodNotice: "ระยะเวลาจนกว่าจะเตือน",
    present: "มี",
    powerSupply: "อุปกรณ์ดึงไฟจาก",
    power: "กำลังไฟ",
    powerBankStatus: "สถานะแบตสำรอง",
    powerBank: "แบตสำรอง",
    powerFromVehicle: "แบตของรถ",
    piece: "ชิ้น",
    ParkingPointNo: "จุดจอดที่",
    progress: "ความคืบหน้า",
    parking: "จอดพาหนะ",
    parkingDistance: "ระยะทาง (กิโลเมตร)",
    parkingDuration: "ระยะเวลาที่จอดรถ (ชั่วโมง:นาที:วินาที)",
    parkingLocation: "สถานที่จอดรถ",
    ParkingReport: "จุดจอดพาหนะ",
    parkingSumDistance: "ระยะทางสะสม (กิโลเมตร)",
    parkingTime: "เวลาที่จอดรถ",
    part_type: "อะไหล่พาหนะ",
    partgroup: "กลุ่มอะไหล่พาหนะ",
    partiallyDamagedCard: "บัตรเสียหายบางส่วน",
    partner: "ผู้เช่าพาหนะ",
    partnerCar: "ผู้เช่าพาหนะ",
    partSetting: "ตั้งค่าอะไหล่",
    partType: "ชนิดอะไหล่พาหนะ",
    passenger: "จำนวนผู้โดยสาร",
    password: "รหัสผ่าน",
    passwordMustBeAtLeast6: "รหัสผ่านอย่างน้อย 6 ตัวอักษร",
    passwordMustMatch: "ยืนยันรหัสผ่านใหม่ไม่ตรง",
    passwordNotSafeValidateText: "รหัสผ่านไม่ปลอดภัย",
    passwordsMustMatch: "รหัสผ่านต้องเหมือนกัน",
    pdf: "PDF",
    pdfFilesUpTo5MBInSize: "ไฟล์ PDF ขนาดไม่เกิน 5MB",
    pending: "รอดำเนินการ",
    performanceLog: " Performance Log",
    period: "ระยะเวลา",
    policyPeriod: "ระยะเวลาต่อเนื่องที่ทำให้ผิดนโยบาย",
    policyPeriodFormat: "(ชั่วโมง:นาที:วินาที)",
    periodOfInsuranceYear: "ระยะเวลาประกันภัยพาหนะ (ปี)",
    permission: "สิทธิ์",
    personal: "บุคคล",
    personalID: "หมายเลขบัตรประชาชน",
    personalIDMustBeNumber: "หมายเลขบัตรประจำตัวประชาชนต้องเป็นตัวเลข",
    personalIDTooLong: "หมายเลขบัตรประจำตัวประชาชนต้องมี 13 ตัว!!!",
    personalIDTooShort: "หมายเลขบัตรประจำตัวประชาชนต้องมี 13 ตัว!!!",
    personalInformation: "ข้อมูลส่วนบุคคล",
    phoneNumber: "เบอร์โทร ",
    phoneNumberMustBeNumber: " ต้องเป็นตัวเลขและอยู่ระหว่าง 8-20 ตัวอักษร",
    photo: "รูป",
    pickup: "จุดรับ",
    pickupData: "วันนัด",
    pickUpDateTime: "วัน/เวลานัด",
    pickUpDetail: "รายละเอียดจุดรับ",
    pickUpLocation: "สถานที่รับ",
    pickUpTime: "เวลานัด",
    picture: "รูปภาพ",
    pictureUpdate: "อัปเดตรูปภาพ",
    pinDown: "ปักหมุด",
    plan_start_deliver_at: "เวลาเริ่มจัดส่งตามแผน",
    plant: "เเพลนท์",
    plantArrivingTime: "เวลาเข้าแพลนต์",
    plantCode: "รหัสแพลนต์",
    plantFactory: "Plant/Factory",
    plateNo: "ทะเบียน",
    plateNoOfInstall: "ทะเบียนรถที่ติดตั้ง",
    plateNoOfUninstall: "ทะเบียนรถที่แจ้งถอด",
    plateNoVehicleCode: "ทะเบียน, รหัสพาหนะ",
    pleaseChooseDateRange: " กรุณาเลือกช่วงวันที่",
    pleaseChooseEvent: "กรุณาเลือกเหตุการณ์",
    pleaseChooseReport: "กรุณาเลือกรายงาน",
    pleaseChooseVehicle: " กรุณาเลือกรถ",
    pleaseEnterEmailAddress: "กรุณากรอกที่อยู่อีเมล",
    pleaseEnterEmailSubject: "กรุณากรอกหัวข้ออีเมล",
    pleaseSelect: "กรุณาเลือก",
    pleaseSelectDriver: "กรุณาเลือกพนักงานขับขี่",
    pleaseSelectExpirationDate: "กรุณาเลือกจำนวนวันหมดอายุ",
    pleaseSelectLocation: "กรุณาเลือกสถานที่",
    pleaseSelectPicture: "กรุณาเลือกรูปภาพ",
    pleaseSelectReportType: "กรุณาเลือกรายงาน",
    pleaseSelectTheReport: "กรุณาเลือกรายงาน",
    pleaseSelectVehicleCode: "กรุณาเลือกรหัสพาหนะ",
    pleaseSelectVehicleRegistration: "กรุณาเลือกทะเบียนพาหนะ",
    pleaseChooseCompany: " กรุณาเลือกบริษัท",
    pleaseChooseLog: "กรุณาเลือกบันทึก",
    po: "ใบสั่งซื้อ",
    point: "คะแนน",
    policy: "นโยบาย",
    policyType: "ชนิดนโยบาย",
    polyGonRadius: "รัศมี",
    PONumber: "หมายเลขใบสั่งซื้อ",
    poNumberIsrequired: " กรุณากรอกหมายเลขใบสั่งซื้อ",
    position: "ตำแหน่ง",
    pos_update: "Pos Update",
    preventReDrawLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    preventRePinLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    preview: "แสดงตัวอย่าง",
    previous: "ย้อนกลับ",
    privilegegroup: "กลุ่มสิทธิ์",
    processing: "กำลังประมวลผล",
    product: "สินค้า",
    product_code: "รหัสสินค้า",
    product_code_duplicate_require: "รหัสสินค้านี้มีอยู่แล้วในระบบ",
    product_code_require: "กรุณากรอกรหัสสินค้า",
    product_description: "รายละเอียด",
    product_name: "ชื่อสินค้า",
    product_name_duplicate_require: "ชื่อสินค้านี้มีอยู่แล้วในระบบ",
    product_name_require: "กรุณากรอกชื่อสินค้า",
    product_type: "ชนิดของผลิตภัณฑ์",
    product_uom_require: "กรุณาเลือกหน่วยนับเล็กสุด",
    productType: "ประเภทสินค้า",
    profile: "โปรไฟล์",
    province: "จังหวัด",
    pto: "PTO",
    publicKey: "รหัสบริษัท",
    pullvehiclesink: "ดึงรถจม",
    purchaseChannel: "ช่องทางการซื้อ",
    plateNoIsNotvalid: "หมายเลขทะเบียนไม่ถูกต้อง",
    pickupAndDropoffEmployee: "รถรับ-ส่งพนักงาน",
    percentageOfTotalVideoRecorded: "เปอร์เซ็นต์ของวีดีโอที่บันทึกได้",
  },
  product_types: {
    animal_food: "อาหารสัตว์",
    bag_cement: "ถุงปูนซีเมนต์",
    bulk_cement: "ปูนซีเมนต์ผง",
    chill: "อาหารแช่เย็น",
    concrete_pump: "ปั๊มคอนกรีต",
    container: "ขนส่งด้วยตู้คอนเทนเนอร์",
    fcr: "FCR",
    frozen: "อาหารแช่แข็ง",
    generic: "ส่งของทั่วไป",
    plate: "Plate",
    readymixconcrete: "คอนกรีตผสมสำเร็จ",
    room_temp: "ห้องควบคุมอุณหภูมิ",
    spool: "Spool",
    valuable: "ของมีค่า",
  },
  q: {
    queue: "คิว",
    qty: "จำนวน",
    quantity: "ปริมาณ",
    quantity_liter: "ปริมาณ (ลิตร)",
  },
  r: {
    ringTone: "เสียงแจ้งเตือน",
    registry_number: "เบอร์ที่ลงทะเบียนเข้าใช้",
    remainingTime: "เวลาที่เหลือ",
    radS: "องศา/วินาที",
    radiuns: "รัศมี",
    radiunsM: "รัศมี (ม.)",
    rainingTime: "ช่วงฝนตก",
    reActivate: "เปิดใช้งานอีกครั้ง",
    reactive_date: "วันที่คาดว่าจะกลับมาใช้งาน",
    received: "Received",
    receiveDate: "วันที่ได้รับเเจ้ง",
    refuel: "เติมเชื้อเพลิง",
    refuelAt: "เติมเชื้อเพลิงเมื่อ",
    RefuelReport: "การเติมเชื้อเพลิง",
    reFuelTitle: "เชื้อเพลิง",
    remainDay: "คงเหลือ (วัน)",
    remainingDevice: "อุปกรณ์ที่เหลืออยู่",
    remainingRequest: "คำขอคงค้าง",
    remark: "หมายเหตุ",
    remark1: "หมายเหตุการจอง 1",
    remark2: "หมายเหตุการจอง 2",
    remarkBooking: "หมายเหตุการจอง",
    remindDate: "วันแจ้งเตือน",
    reminderData: "วันแจ้งเตือน",
    reminderTime: "เวลาแจ้งเตือน",
    renewalDate: "วันต่ออายุ",
    RepairDevice: "เเจ้งย้าย",
    replay: "เส้นทางเดินทางย้อนหลัง",
    replayDetail: "รายละเอียดเส้นทางเดินทางย้อนหลัง",
    replayNew: "เส้นทางเดินทางย้อนหลัง (ใหม่)",
    report: "รายงาน",
    report_daily: "รายวัน",
    report_monthly: "รายเดือน",
    report_name: "ชื่อรายงาน",
    report_taxifare: "รายงานรายรับ",
    report_weekly: "รายสัปดาห์",
    report_yearly: "รายปี",
    reportWithdrawfuelcredit: "รายงานการเบิกน้ำมัน",
    reportDepositfuelcredit: "รายงานการเพิ่มน้ำมัน",
    reportFlow: "รายงานปริมานการใช้น้ำ",

    reportAlarmFrequencyClassifiedByDailyDriver:
      "ความถี่ Alarm แยกตามพนักงานขับขี่ รายวัน",
    reportCardSwipeBehaviorBeforeDrivingDaily:
      "พฤติกรรมการรูดบัตรก่อนขับขี่ รายวัน",
    reportSummarizingTheNumberOfOffSiteParking:
      "สรุปจำนวนครั้งการจอดพาหนะนอกพื้นที่",
    reportType: "ประเภทรายงาน",
    requestList: "รายการคำขอ",
    require: "โปรดระบุ",
    requiredFieldCompany: "กรุณากรอกชื่อบริษัท",
    requiredFieldConfirmPassword: "กรุณายืนยันรหัสผ่าน",
    requiredFieldDischargeDenominator: "กรุณากรอกค่าคำนวณปริมาณในการเท",
    requiredFieldEmail: "กรุณากรอกอีเมล",
    requiredFieldFinishRefuel: "กรุณาเลือกวันที่จบการเติมเชื้อเพลิง",
    requiredFieldFirstName: "กรุณากรอกชื่อจริง",
    requiredFieldFuelTankSize: "กรุณากรอกความจุถังเชื้อเพลิง",
    requiredFieldLastName: "กรุณากรอกนามสกุล",
    requiredFieldLimitDrivingHourTime:
      "กรุณากรอกจำกัดระยะเวลาวิ่งงานต่อเนื่อง(นาที)",
    requiredFieldNameTitle: "กรุณากรอกชื่อหัวข้อ",
    requiredFieldPartType: "กรุณาเลือกชนิดอะไหล่พาหนะ",
    requiredFieldPassword: "กรุณากรอกรหัสผ่าน",
    requiredFieldPersonalID: "กรุณากรอกหมายเลขบัตรประจำตัวประชาชน",
    requiredFieldPhoneNumber: "กรุณากรอกเบอร์โทรศัพท์",
    requiredFieldPlateNo: "กรุณากรอกทะเบียนรถ",
    requiredFieldQuantity: "กรุณากรอกปริมาณ(ลิตร)",
    requiredFieldStartRefuel: "กรุณาเลือกวันที่เติมเชื้อเพลิง",
    requiredFieldTotalPrice: "กรุณากรอกราคารวม(บาท)",
    requiredFieldUsername: "กรุณากรอกชื่อผู้ใช้งาน",
    requiredFieldVehicle: "กรุณาเลือกพาหนะ",
    requiredFieldVehicleCode: "กรุณากรอกรหัสพาหนะ",
    requiredFieldVehicleGroupName: "กรุณากรอกชื่อกลุ่มพาหนะ",
    requiredFieldVehicleGroupCode: "กรุณากรอกรหัสกลุ่มพาหนะ",
    requiredFieldVehicleType: "กรุณาเลือกประเภทพาหนะ",
    resendEmail: "ส่งอีเมลอีกครั้ง",
    reset: "รีเซ็ต",
    rfid: "RFID",
    risk: "เสี่ยง",
    RiskbehaviourReport: "พฤติกรรมการขับขี่ไม่ปลอดภัย",
    round: "รอบ",
    route: "เส้นทางการวิ่ง",
    route_iconsiam: "เส้นทางการวิ่ง Iconsiam",
    route_master_name: "ชื่อแผนการจัดส่ง",
    routemaster: "แผนการจัดส่ง",
    routemaster_name_duplicate: "ชื่อแผนการจัดส่งนี้มีอยู่แล้วในระบบ",
    routemaster_name_require: "กรุณากรอกชื่อแผนการจัดส่ง",
    routeName: "ชื่อเส้นทาง",
    routeRadius: "รัศมีของเส้นทาง",
    routeRadiusM: "รัศมีของเส้นทาง (ม.)",
    radiusIsRequire: "กรุณากรอกรัศมี",
    rpm: "RPM",
    running: "วิ่ง",
    report_gpsLostContact: "พาหนะขาดการติดต่อ",
    report_taxisos: "การกดปุ่มฉุกเฉิน",
    report_summarylinenotification: "สรุปการแจ้งเตือนจากไลน์",
    rtspAuthen: "RTSP Authen",
    reversing_light: "ไฟถอย",
    requiredField: "จำเป็นต้องกรอก",
    required: "จำเป็นต้องกรอก",
    route_master_type: "ประเภทแผนการจัดส่ง",
    RTSPMain: "RTSP หลัก",
    RTSPSub: "RTSP รอง",
    RTSPThird: "RTSP 3rd",
  },
  scheduleReport: {
    excel: "Excel",
    pdf: "PDF",
    report_comparevehiclescco: "ทั่วไป >> รายงาน เปรียบเทียบพาหนะ SCCO",
    report_dailyiconsiam: "ทั่วไป >> รายงานการให้บริการเรือ / รถ แบบรายวัน",
    report_distfromlastmaintenance:
      "ทั่วไป >> รายงานพาหนะที่มีระยะทางวิ่งสะสมล่าสุด",
    report_dozeoffrisk: "ทั่วไป >> รายงานเสี่ยงหลับใน",
    report_driverinformation: "ทั่วไป >> รายงานข้อมูลพนักงานขับขี่",
    report_drivingdistance: "ทั่วไป >> รายงานระยะทางการวิ่ง",
    report_drivinghour: "ทั่วไป >> รายงานพฤติกรรมการขับขี่",
    report_event: "ทั่วไป >> รายงานเหตุการณ์พาหนะรายคัน",
    report_maintenance: "ทั่วไป >> รายงานพาหนะที่ต้องบำรุงรักษา",
    report_numberofdaysworked: "ทั่วไป >> รายงานจำนวนวันที่พาหนะวิ่งงาน",
    report_parking: "ทั่วไป >> รายงานจุดจอดพาหนะ",
    report_summaryiconsiam:
      "ทั่วไป >> รายงานสรุปรอบการให้บริการเรือ / รถ แบบเฉลี่ยรายวัน",
    report_temperature: "ทั่วไป >> รายงานอุณหภูมิ",
    report_truckcurrentlocation: "ทั่วไป >> รายงานที่อยู่ปัจจุบันของพาหนะ",
    report_truckusage: "ทั่วไป >> รายงานการใช้งานพาหนะ",
    report_truckusage_sjc_monthly:
      "ทั่วไป >> รายงานสรุปเวลาในการใช้งานรถรายเดือน บริษัทเอสเจซีคอนกรีต",
    report_summarylinenotification: "ทั่วไป >> สรุปการแจ้งเตือนจากไลน์",
    report_fuelremainrate: "ทั่วไป >> รายงานน้ำมันคงเหลือ",
    report_truckengineon: "ประสิทธิภาพ >> รายงานพาหนะติดเครื่อง",
    report_truckengineoff: "ประสิทธิภาพ >> รายงานพาหนะดับเครื่อง",
  },
  sku: {
    from_uom: "จากหน่วยนับ",
    from_uom_require: "กรุณาเลือกจากหน่วยนับ",
    quality: "จากจำนวน",
    quality_require: "กรุณากรอกจากจำนวน",
    uom_require: "กรุณาเลือกหน่วยนับ",
    weight: "น้ำหนัก",
    weight_require: "กรุณากรอกน้ำหนัก",
  },
  t: {
    tax: "ภาษี",
    taxno: "หมายเลขกรมธรรม์ภาษี",
    TotalDistance: "ระยะทางรวม",
    tagID: "รหัสเเท็ก",
    taxiMeter: "Taxi Meter",
    taxNo: "Tax No.",
    technician: "ช่าง",
    telnoAE: "เบอร์โทรศัพท์ AE",
    temperature: "อุณหภูมิ",
    temperature1: "อุณหภูมิ 1",
    temperature2: "อุณหภูมิ 2",
    TemperatureReport: "อุณหภูมิ",
    temporaryTracking: "การติดตามรถชั่วคราว",
    theDayThatTheCarPulled: "วันที่ดึงพาหนะจม",
    theDriverIsIicenseReportHasExpired: "การแจ้งเตือนใบขับขี่หมดอายุ",
    theRoute: "เส้นทาง",
    TheUsualRoute: "เส้นทางปกติ",
    trackingLink: "ลิงค์ติดตาม",
    thisMonth: "เดือนนี้",
    thisYear: "ปีนี้",
    ticket: "ตั๋ว",
    time: "เวลา",
    time_1440: "24 ชั่วโมง",
    time_15: "15 นาที",
    time_180: "3 ชั่วโมง",
    time_30: "30 นาที",
    time_300: "5 ชั่วโมง",
    time_420: "7 ชั่วโมง",
    time_60: "1 ชั่วโมง",
    time_720: "12 ชั่วโมง",
    time_entry: "เวลาเข้า",
    time_out: "เวลาออก",
    time_period: "ช่วงระยะเวลา",
    time_travel: "เวลาเดินทาง",
    time_use_in_location: "เวลาที่อยู่ในสถานี",
    timeGoToLocation: "เวลาแผนเข้า-ออกสถานที่",
    times: "ครั้ง",
    timeThatCanBeUsed: "ระยะเวลาที่สามารถใช้งานระบบ",
    timeZone: "Time Zone",
    tire: "จำนวนยาง",
    title: "คำนำหน้า",
    titleAccount: "บัญชีผู้ใช้",
    to: " ถึง ",
    to_email: "ส่งถึง",
    today: "วันนี้",
    toggleHeatMap: "Toggle Heat Map",
    tokenExpired: "เซสชันหมดอายุ",
    tool: "เครื่องมือ",
    tooLong20: " ไม่ควรยาวเกิน 20 ตัวอักษร",
    tooLong25: " ไม่ควรยาวเกิน 25 ตัวอักษร",
    tooLong50: " ไม่ควรยาวเกิน 50 ตัวอักษร",
    tools: "เครื่องมือ",
    tooShot8: " ไม่ควรสั้นกว่า 8 ตัวอักษร",
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      "Alert 5 อันดับแรกของแต่ละประเภท แบ่งตามช่วงเวลา รายเดือน",
    topCustomer: "Top Customer",
    topEarning: "Top Earning",
    toSiteDistance: "ระยะทางขาไป",
    total: "ทั้งหมด",
    totalDistance: "ระยะทางรวม (กิโลเมตร)",
    totalDuration: "ระยะเวลารวม (วัน:ชั่วโมง:นาที)",
    totalEmergencyCase: "กรณีฉุกเฉินทั้งหมด",
    totalNumberofMinutesEnteredSlowly: "จำนวนนาทีเข้าช้ารวม (นาที)",
    totalPrice: "ราคารวม",
    totalPrice_baht: "ราคารวม (บาท)",
    tracking: "ติดตามยานพาหนะ",
    TrackingdataReport: "รายละเอียดพิกัดพาหนะ",
    trackingNo: "หมายเลขการติดตาม",
    trainer: "ผู้ฝึกอบรม",
    trainingDate: "วันที่อบรม",
    traininglist: "รายการฝึกอบรม",
    trip: "ทริป",
    trip_date: "ช่วงเวลาเริ่ม-สิ้นสุดทริป",
    trip_duration: "ช่วงเวลาเริ่ม-สิ้นสุดทริป",
    trip_duration_management: "กำหนดระยะเวลาทริป",
    trip_report_menu: "ทริป",
    TripbyengineoffonReport: "การเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์",
    tripCannotEditDueToOverTime:
      "ไม่สามารถแก้ไขสถานที่ได้เนื่องจากเกินเวลาเริ่มทริปไปแล้ว",
    tripCode: "รหัสทริป",
    tripCodeDup: "รหัสทริปมีในระบบแล้ว",
    tripInfo: "ข้อมูลทริป",
    tripInfoDateTime: "ข้อมูลทริป วันที่/เวลา",
    tripStartAt: "เวลาเริ่มทริป",
    tripStatus: "สถานะทริป",
    TripsumbylocationReport: "สรุปจำนวนงานตามสถานที่",
    TripsumbyvehReport: "สรุปการวิ่งแยกตามพาหนะ",
    TripsummaryReport: "สรุปการวิ่งแยกตามทริป",
    TriptemperatureReport: "สรุปอุณหภูมิแยกตามทริป",
    truckCategory: "ประเภทรถบรรทุก",
    TruckcurrentlocationReport: "ที่อยู่ปัจจุบันของพาหนะ",
    truckDriverMatching: "จับคู่พนักงานขับขี่",
    TruckengineoffReport: "พาหนะดับเครื่อง",
    TruckengineonReport: "พาหนะติดเครื่อง",
    TrucknotuseReport: "พาหนะไม่ได้วิ่งงาน",
    truckType: "ชนิดรถบรรทุก",
    TruckusageReport: "การใช้งานพาหนะ",
    TruckusageSJCMonthlyReport: "สรุปเวลาในการใช้งานรถรายเดือน",
    type: "ประเภท",
    type_duration: "ตามช่วงเวลา",
    type_person: "รายบุคคล",
    type_problem: "อาการของปัญหา",
    type_report: "ประเภทรายงาน",
    type_trip: "รายทริป",
    typeLocation: "ชนิดของสถานที่",
    typeMaintenance: "ประเภท",
    telSetting: "ตั้งค่าส่วนโทรศัพท์",
    temperatureOffset: "Temperature Offset",
    temperatureSetting: "ตั้งค่าอุณหภูมิ",
    tip_code: "หมายเลขใบสั่งงาน",
    technician_name: "ชื่อช่าง",
    technician_telno: "เบอร์โทรศัพท์ช่าง",
    totalMinutesOfVideoRecorded: "นาทีรวมของวิดีโอที่บันทึกได้",
    totalMinutesEngineRunning: "นาทีรวมที่เครื่องยนต์ทำงาน",
    timePeriod: "ช่วงเวลา",
    ticketTime: "เวลาตั๋ว",
    ticketOrder: 'ลำดับตั๋ว',
    ticketPlantName: 'ชื่อโรงงาน',
  },
  time: {
    seconds: "วินาที",
    minutes: "นาที",
  },
  s: {
    soundPreview: "เสียงตัวอย่าง",
    speedToAlertTheDriver: "ความเร็วเพื่อแจ้งเตือนคนขับ (กม./ชม.)",
    sim: "ความแรงสัญญาณ",
    SummaryReport: "สรุปรายงาน",
    satelliteCount: "จำนวนดาวเทียม",
    seeMore: "ดูเพิ่มเติม",
    showAndHide: "แสดง/ซ่อน",
    showAndHideLocation: "แสดง/ซ่อน ไอคอน",
    setupFullTankOfFuel: "ตั้งค่าน้ำมันเต็มถัง",
    saveTableSize: "บันทึกขนาดตาราง",
    safety: "ความปลอดภัย",
    salesChannels: "ช่องทางการขาย",
    salesMan: "พนักงานขาย",
    satellite: "ดาวเทียม",
    save: "บันทึก",
    saveNext: "บันทึกเเละถัดไป",
    scan_date_at: "วันที่อัพโหลด",
    SCCOCarComparisonReport: "เปรียบเทียบพาหนะของ SCCO",
    schedule_time: "เวลาแจ้งเตือน",
    schedule_type: "ชนิดการแจ้งเตือน",
    scheduled: "นัดช่าง",
    scheduled_day: "วันแจ้งเตือน",
    schedulereport: "แจ้งเตือนรายงาน",
    scoreEconomy: "คะแนนความประหยัด (คะแนน)",
    scorePlanAchievement: "คะแนนความสำเร็จตามแผน (คะแนน)",
    scoreSafety: "คะแนนความปลอดภัย (คะแนน)",
    scoreTotal: "คะแนนรวม (คะแนน)",
    search: "ค้นหา",
    searchDashCameras: "พิมพ์ค้นหาจากเลขทะเบียนรถ, รหัสพาหนะ ...",
    searchDirections: "ค้นหาเส้นทาง",
    searchPlants: "ค้นหา",
    searchText: "ค้นหาคำ",
    searchTextFromPlateNo: "ค้นหาจากเลขทะเบียน ...",
    searchModel: "ค้นหาจากรุ่น",
    second_dest: "จุดสิ้นสุด",
    secondaryPhoneNumber: "เบอร์โทร 2",
    seenAll: "เห็นข้อมูลทั้งหมด",
    select_event: "เลือกเหตุการณ์",
    selectAssignTo: "เลือกผู้รับมอบหมาย",
    selectCriticalityLevel: "เลือกระดับความรุนเเรง",
    selectDate: "เลือกวันที่",
    selected: "เลือก",
    selectedDevice: "อุปกรณ์ที่เลือก",
    selectedVehicleAlready: "พาหนะที่เลือกไว้",
    selectFile: "เลือกไฟล์",
    selectNotificationChannel: "เลือกช่องทางเเจ้งเตือน",
    selectOne: "เลือก 1 รายการ",
    selectPlateNo: "เลือกทะเบียน",
    selectStatus: "เลือกสถานะ",
    selectTime: "เลือกเวลา",
    selectVehicleGroup: "เลือกกลุ่มพาหนะ",
    send: "ส่ง",
    send_data_before_delivery: "ใช้ข้อมูลวันก่อนหน้า",
    send_email: " ส่งอีเมล",
    sendAsAGroupOfVehicles: "ส่งเเบบกลุ่มพาหนะ",
    sender: "ผู้ส่ง",
    sendFailure: "ส่งผิดพลาด",
    sendSelectedVehicles: "ส่งเเบบเลือกพาหนะ",
    sentBy: "ส่งโดย",
    sentSuccessfully: "ส่งเรียบร้อย",
    serviceinstalldevice: "ใบแจ้งติดตั้ง",
    servicemovedevice: "ใบเเจ้งย้าย",
    Servicerepairdevice: "แจ้งซ่อม/ถอดอุปกรณ์",
    setTheRoute: "กำหนดเส้นทาง",
    setting: "ตั้งค่า",
    setting_filter: " ตั้งค่ารายงาน",
    setup: "ติดตั้ง",
    shaft: "จำนวนเพลาล้อ",
    sharedAt: "แชร์เมื่อ",
    shareFrom: "พาหนะที่ถูกแชร์",
    shareLocation: "แชร์สถานที่",
    shareTo: "พาหนะที่แชร์",
    ship_to: "รหัสไซต์งาน",
    shippingInfo: "ข้อมูลการส่งของ",
    shipTo: "ส่งไปยัง",
    shock: "สะเทือน",
    shopee: "ช้อปปี้",
    show: "เเสดง",
    showAll: "เเสดงทั้งหมด",
    showTraffic: "แสดงการจราจร",
    simNumber: "หมายเลขซิม",
    site: "ไซต์",
    siteCode: "รหัสไซต์",
    sizeAndWeight: "ขนาดเเละน้ำหนัก",
    slideWrongCardType: "รูดบัตรผิดประเภท",
    slidingCard: "รูดบัตร",
    smallTruck: "รถบรรทุกขนาดเล็ก",
    smsNotifyTo: "ส่ง sms แจ้งเตือนไปที่หมายเลข",
    someparts: "บางส่วน..",
    sort: "เรียง",
    sos: "sos",
    southeastAsia: "เอเชียตะวันออกเฉียงใต้",
    specialPrivilege: "สิทธิพิเศษ",
    speed: "ความเร็ว",
    speedKmPerHr: "ความเร็ว (กม./ชม.)",
    speedLimited: "ความเร็วเกินกำหนด",
    speedOverLimit: "ความเร็วเกินกำหนด",
    speedOverLimitByDriver: "ความเร็วเกินกำหนดตามพนักงานขับขี่",
    speedOverLimitByVehicleGroup: "ความเร็วเกินกำหนดตามกลุ่มพาหนะ",
    speedOverLimitByVehicleType: "ความเร็วเกินกำหนดตามประเภทพาหนะ",
    SpeedoverlimitsummaryReport: "สรุปความเร็วเกินกำหนด",
    speedRange: "ช่วงความเร็ว (กม./ชม.)",
    speedRangeNoKm: "ช่วงความเร็ว",
    start_daily_time: "เวลาเริ่มในแต่ละวัน",
    start_deliver_at: "เวลาเริ่มทริป",
    startAndEndValidDateDesc: "ช่วงวันที่อนุญาตให้ระบบนำข้อมูลพาหนะไปใช้งาน",
    startAt: "เวลาเริ่ม",
    startDate: "วันเริ่มต้น",
    startDeliveryAt: "วัน-เวลาที่เริ่มทริป",
    startDeliveryDay: "วันที่เริ่มทริป",
    startDestinationCode: "รหัสต้นทาง",
    startDestinationName: "ชื่อต้นทาง",
    startEndDate: "วันที่เริ่มต้น - วันที่สิ้นสุด",
    startLocationCode: "รหัสต้นทาง",
    startLocationName: "ชื่อต้นทาง",
    startRefuel: "เติมเชื้อเพลิงเมื่อ",
    startTrip: "จุดเริ่มต้นทริป",
    startValidDate: "วันที่เริ่มใช้พาหนะ",
    state: "สถานะภาพ",
    status: "สถานะ",
    status_sheet: "สถานะใบงาน",
    status_download_file: "สถาน���การดาวน์โหลดไฟล์",
    status_engineoff: "ดับเครื่อง",
    status_moving: "พาหนะวิ่ง",
    status_parked: "จอดพาหนะ",
    status_vehicle: "สถานะพาหนะ",
    statusVehicleVideo: "สถานะ",
    stopWithEngineOff: "จอดดับเครื่อง",
    stopWithEngineOn: "จอดไม่ดับเครื่อง",
    submit: "บันทึก",
    submit_name: "ชื่อผู้ยื่นเรื่อง",
    submit_telno: "เบอร์ติดต่อผู้ยื่นเรื่อง",
    submitName: "ชื่อผู้ยื่นเรื่อง",
    submitTelno: "เบอร์ติดต่อผู้ยื่นเรื่อง",
    subType: "ประเภทย่อย",
    success: "สำเร็จ",
    SummaryiconsiamReport: "สรุปรอบการให้บริการเรือ / รถ แบบเฉลี่ยรายวัน",
    summaryInfo: "สรุปข้อมูล",
    SummaryKPIByDriverReport: "KPI แยกตามพนักงานขับขี่",
    SummaryKPIReport: "สรุปรายงาน KPI",
    support: "สนับสนุน",
    surplus: "เหลือ",
    selectData: "เลือกข้อมูล",
    shell: "Shell",
    serverIP: "Server/IP",
    serverPath: "Path",
    seconds: "วินาที",
    specify_reason_not_send_dlt: "กรุณาระบุเหตุผลที่ไม่ส่งเข้ากรม",
    search_by: "ค้นหาตาม",
    search_plate_no: "ค้นหาทะเบียน",
    search_driver: "ค้นหาผู้ขับขี่",
    search_trip: "ค้นหาหมายเลขใบสั่งงาน",
    summaryDrivingLogReport: "สรุปบันทึกการขับขี่",
    startTime: "เวลาเริ่ม",
    selectAll: "เลือกทั้งหมด",
    showLocationName: "แสดงชื่อสถานที่",
    showOnlyDataLessThan100: "แสดงเฉพาะข้อมูลที่น้อยกว่า 100",
    selectTemplate: "เลือกแบบ",
    site_name: 'ชื่อไซต์งาน',
  },
  trip_import: {
    confirm_send_require: "กรุณากรอกยืนยันการส่งโดย(เข้าจุด/ออกจุด)",
    date_arrive_at_require: "กรุณากรอกวันที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้า",
    date_out_at_require: "กรุณากรอกวันที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้า",
    date_start_trip_at_require: "กรุณากรอกวันที่เริ่มทริป",
    do_number_require: "กรุณากรอกเลขที่ใบส่งสินค้า",
    error_import: "มีข้อผิดพลาดเกิดขึ้น",
    location_code_not_find:
      "รหัสจุดส่งสินค้า/จุดโหลดสินค้านี้ไม่มีอยู่ในระบบ :",
    location_code_require: "กรุณากรอกรหัสจุดส่งสินค้า/จุดโหลดสินค้า",
    location_name_require: "กรุณากรอกชื่อจุดส่งสินค้า/จุดโหลดสินค้า",
    product_code_not_find: "รหัสสินค้านี้ไม่มีอยู่ในระบบ :",
    product_code_require: "กรุณากรอกรหัสสินค้า",
    product_name_require: "กรุณากรอกสินค้า",
    quality_require: "กรุณากรอกจำนวน",
    sku_not_find: "หน่วยนับสินค้านี้ไม่มีอยู่ในระบบ :",
    sku_require: "กรุณากรอกหน่วยนับ",
    time_arrive_at_require: "กรุณากรอกเวลาที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้า",
    time_out_at_require: "กรุณากรอกเวลาที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้า",
    time_start_trip_at_require: "กรุณากรอกเวลาที่เริ่มทริป",
    trip_code_require: "กรุณากรอกรหัสทริป",
    vehicle_code_not_find: "รหัสพาหนะนี้ไม่มีอยู่ในระบบ :",
    vehicle_code_not_install: "รหัสพาหนะนี้ยังไม่ได้ติดตั้งในระบบ :",
    vehicle_code_require: "กรุณากรอกรหัสพาหนะ",

    date_out_at_format_incorrect:
      "กรุณากรอกวันที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :",
    time_out_at_format_incorrect:
      "กรุณากรอกเวลาที่ควรออกจากจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :",
    date_start_trip_at_format_incorrect:
      "กรุณากรอกวันที่เริ่มทริปให้ถูกรูปแบบ :",
    time_start_trip_at_format_incorrect:
      "กรุณากรอกเวลาที่เริ่มทริปให้ถูกรูปแบบ :",
    time_arrive_at_format_incorrect:
      "กรุณากรอกเวลาที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :",
    date_arrive_at_format_incorrect:
      "กรุณากรอกวันที่ควรถึงจุดส่งสินค้า/จุดโหลดสินค้าให้ถูกรูปแบบ :",
    remark_more_than_50: "หมายเหตุต้องไม่เกิน 50 ตัวอักษร",
  },

  managedocument_import: {
    error_import: "มีข้อผิดพลาดเกิดขึ้น",
    No_vehicle_code_in_system: "ไม่มีรหัสพาหนะนี้ในระบบ",
    Vehicle_code_is_required: "กรุณากรอกรหัสพาหนะ",
    Vehicle_plate_no_is_required: "กรุณากรอกทะเบียนพาหนะ",
    Tax_Expire_Date_is_required_when_tax_is_provided:
      "กรุณาใส่เลขภาษีและวันหมดอายุภาษี",
    Act_Expire_Date_is_required_when_act_is_provided:
      "กรุณาใส่เลขกรมธรรภ์และวันหมดอายุกรมธรรภ์",
    Insurance_Expire_Date_is_required_when_insurance_is_provided:
      "กรุณาใส่เลขประกันและวันหมดอายุประกัน",
    Tax_number_is_required_when_tax_is_provided:
      "กรุณาใส่เลขภาษีและวันหมดอายุภาษี",
    Act_number_is_required_when_act_is_provided:
      "กรุณาใส่เลขกรมธรรภ์และวันหมดอายุกรมธรรภ์",
    Insurance_number_is_required_when_insurance_is_provided:
      "กรุณาใส่เลขประกันและวันหมดอายุประกัน",
    Tax_format_is_incorrect:
      "กรุณากรอกวันที่หมดอายุภาษีให้ตรงฟอแมทต์ dd/mm/yyyy",
    Act_format_is_incorrect:
      "กรุณากรอกวันที่หมดอายุกรมธรรภ์ให้ตรงฟอแมทต์ dd/mm/yyyy",
    Insurance_format_is_incorrect:
      "กรุณากรอกวันที่หมดอายุประกันให้ตรงฟอแมทต์ dd/mm/yyyy",
  },

  u: {
    unassign: "Unassign",
    unBroken: "ไม่เสีย",
    uninstall: "ถอด",
    unitPrice: "หน่วยละ (บาท)",
    unknown: "ไม่ระบุ",
    unMatch: "ยกเลิกการจับคู่",
    uom: "หน่วยนับ",
    uom_description: "รายละเอียดหน่วยนับ",
    uom_name: "ชื่อหน่วยนับ",
    uom_name_duplicate_require: "ชื่อหน่วยนับนี้มีอยู่แล้วในระบบ",
    uom_name_require: "กรุณากรอกชื่อหน่วยนับ",
    uom_smallest: "หน่วยนับเล็กสุด",
    updatedAt: "อัปเดตเมื่อเวลา",
    updated_driving_license_at: "วันล่าสุดที่ทำการรูปใบขับขี่",
    updatedBy: "เเก้ไขข้อมูลโดย",
    updateLocation: "อัปเดตตำแหน่ง",
    updatePictures: "อัปเดตรูปภาพ",
    upload: "Upload",
    uploaderIsRequired: " กรุณาอัปโหลดไฟล์",
    use_account_name: "ผู้ใช้งาน",
    use_date_time: "วันเวลาที่ใช้",
    user: "ผู้ใช้งาน",
    userClose: "ผู้ใช้งานปิดเคส",
    userExpire: "ระยะเวลาสิ้นสุดการใช้งาน",
    userExpireAt: "ผู้ใช้งานสามารถใช้ระบบได้ถึง",
    userGroup: "กลุ่มผู้ใช้งาน",
    userGroupPremission: "กลุ่มผู้ใช้งาน",
    userName: "ชื่อผู้ใช้งาน",
    userProfile: "ข้อมูลผู้ใช้",
    userLogonReport: "รายงานประวัติการเข้าใช้งานระบบรายคน",
    uploadWhenConnectedWifi: "อัปโหลดวิดีโอเมื่อเชื่อมต่อ Wifi",
    uploadVideoCellular: "อัปโหลดวิดีโอ Cellular",

    userV1: "ชื่อผู้ใช้งาน V1",
    userV2: "ชื่อผู้ใช้งาน V2",
    uploadYourFile: "อัปโหลดไฟล์ของคุณ",
    uploadFile: "อัปโหลดไฟล์",
  },
  v: {
    voiceManagement: "จัดการเสียง",
    validDate: "Valid Date",
    valueIsRequired: "กรุณากรอกข้อมูลให้ครบถ้วน",
    vehicle: "พาหนะ",
    vehicle_type: "ประเภทพาหนะ",
    vehicleAndDriver: "พาหนะและพนักงานขับขี่",
    vehicleCertNumber: "หมายเลขใบอนุญาตขับรถ",
    vehicleCode: "รหัสพาหนะ",
    vehicleColor: "สีพาหนะ",
    vehicleDetail: "ข้อมูลรายละเอียดพาหนะ",
    vehicleGroup: "กลุ่มพาหนะ",
    vehicleGroupCode: "รหัสกลุ่มพาหนะ",
    vehicleGroupName: "ชื่อกลุ่มพาหนะ",
    VehicleinareaReport: "พาหนะเข้าพื้นที่",
    VehicleinformationdltReport: "ข้อมูลการเดินรถ DLT",
    vehicleModel: "รุ่นพาหนะ",
    VehicleperformanceconcludeReport: "สรุปประสิทธิภาพพาหนะรายคัน",
    vehicleRegisterType: "Vehicle Register Type",
    vehicleSharing: "แชร์พาหนะ",
    vehicleStatus: "สถานะพาหนะ",
    vehicleType: "ประเภทพาหนะ",
    VelocitysummaryReport: "สรุปความเร็วเกิน",
    vendor: "Vendor",
    verifyYourEmailAddress: "ยืนยันที่อยู่อีเมลของคุณ",
    version: "เวอร์ชั่น",
    video: "MDVR Live Stream (วีดีโอเรียลไทม์จาก MDVR)",
    videos: "วิดีโอ",
    video_type: "ลักษณะการถ่ายวิดีโอ",
    videoDownload: "ดาวน์โหลดวิดีโอ",
    videospecial: "MDVR Live Stream (พิเศษ)(วีดีโอเรียลไทม์จาก MDVR)",
    view: "ดู",
    vnCode: "รหัสพาหนะ",
    voluntary: "ประกันภัยรถยนต์ภาคสมัครใจ",
    vol: "ค่าสัญญาณไฟ",
    vehicleGreaterSpeed: "รถที่ความเร็วมากกว่า (กม./ชม.)",
    videoSetting: "ตั้งค่าส่วนวิดีโอ",
    videoSize: "ขนาดพื้นที่ในการจัดเก็บวิดีโอ(GB)",
    videoSnapshotReport: "รายงาน Snapshot",
    vehiclePlateNo: "ทะเบียนพาหนะ",
    vehicleData: "ข้อมูลพาหนะ",
    videoTime: "เวลาวิดีโอ",
    vehicleEntryAlert: "แจ้งเตือนรถเข้าพื้นที่"
  },
  policy: {
    default: "มาตรฐาน",
    night: "กลางคืน",
    raining: "ฝนตก",
    downhill: "ทางลงเขา",
    uphill: "ทางขึ้นเขา",
    restricted_area: "เขตจำกัดพื้นที่",
    green_zone: "เขตหวงห้าม",
    speed_limit_area: "เขตจำกัดความเร็ว",
    stop: "จอดรถ",
    idle: "จอดรถติดเครื่องยนต์",
    pto_gate1_on: "เปิด PTO/ประตู1",
    boom_gate2_on: "เปิด Boom/ประตู2",
    fuel_anomaly: "ปริมาณน้ำมันผิดปกติ",
    temperature: "อุณหภูมิ",
    working_within_a_given_period: "ทำงานในช่วงเวลาที่กำหนด",
    work_longer_than_the_time_limit: "ทำงานเกินจำนวนชั่วโมงที่กำหนด",
    work_distance_less_than_rule: "วิ่งน้อยกว่าระยะทางที่กำหนด",
    vehicle_move_out_factory_long_time: "รถไม่อยู่ในสังกัดนานเกินวันที่กำหนด",
    vehicle_disconnect: "รถขาดการติดต่อ",
    vehicle_continous_driving: "ขับรถต่อเนื่อง",
    no_parking_area: "จุดห้ามจอด",
    risk_area: "จุดเสี่ยง",
    accelerate_brake: "ออกตัวกระชาก/เบรคกระทันหัน",
    speed_limit_of_the_event: "speed limit of the event",
    stay_on_the_site_for_longer_than_the_allotted_time:
      "stay on the site for longer than the allotted time",
    the_drum_idling_during_the_delivery_in_transit:
      "the drum idling during the delivery in transit",
    stay_on_the_plant_for_longer_than_the_allotted_time:
      "stay on the plant for longer than the allotted time",
    return_for_longer_than_the_allotted_time:
      "return for longer than the allotted time",
    unauthorized_discharge: "unauthorized discharge",
    delivery_longer_than_the_allotted_time:
      "delivery longer than the allotted time",
    vehicle_not_ready_at_working_time: "vehicle not ready at working time",
    vehicle_not_deliver_at_plan_time: "vehicle not deliver at plan time",
    vehicle_not_arrive_site_at_plan_time:
      "vehicle not arrive site at plan time",
    alert_when_vehicle_not_follow_maintenance_rule:
      "alert when vehicle not follow maintenance rule",
    //
    speed_limit_and_zone: "จำกัดความเร็วและพื้นที่",
    event: "เหตุการณ์",
    speed_limit_of_the_event: "จำกัดความเร็วของเหตุการณ์",
    trip: "ทริป",
    maintenance: "การบำรุงรักษา",
    outsidePolicy: "ภายนอกสถานที่",
    insidePolicy: "ภายในสถานที่",
    notSpecified: "ไม่ระบุ",
  },
  variable: {
    abnormal_behavior: "พฤติกรรมการขับขี่ไม่ปลอดภัย",
    abnormal_temperature: "อุณหภูมิผิดปกติ",
    boom: "BOOM",
    compulsory_motor_insurance: "ประกันภัยรถยนต์ภาคบังคับ",
    driving: "ขับพาหนะ",
    driving_hour: "ชั่วโมงการขับขี่",
    engine_oil: "น้ำมันเครื่อง",
    event: "เหตุการณ์",
    fuel: "เชื้อเพลิง",
    high: "สูง",
    insurance: "ประกันภัย",
    invalidCredentials: "ข้อมูลเซสชันไม่ถูกต้อง",
    invalidOldPassword: "รหัสผ่านไม่ถูกต้อง",
    low: "ต่ำ",
    maintenance_vehicle: "พาหนะ",
    medium: "กลาง",
    No: "ไม่",
    no_entry: "เข้าพื้นที่",
    no_exit: "ออกพื้นที่",
    off: "ปิด",
    on: "เปิด",
    parking: "จอดพาหนะ",
    parking_area: "จุดจอด",
    prohibited_area: "พื้นที่",
    pto_door: "เปิด PTO/ประตู",
    safety: "ความปลอดภัย",
    sensor: "เซ็นเซอร์",
    sos: "SOS",
    speed: "ความเร็ว",
    speed_limit: "ความเร็ว",
    speed_limit_area: "ความเร็วในพื้นที่",
    stop: "จอดรถทั่วไป",
    stop_engine_on: "จอดรถติดเครื่องทั่วไป",
    stop_engine_on_with_area: "จอดรถติดเครื่องระบุพื้นที่",
    stop_with_area: "จอดรถทั่วไประบุพื้นที่",
    stop_with_engine_off: "จอดรถดับเครื่อง",
    success: "สำเร็จ",
    suspension: "ช่วงล่าง",
    taxi: "Taxi",
    temperature: "อุณหภูมิ",
    timeline_aggressive_acceleration: "ออกตัวกระชาก",
    timeline_speed_over: "ความเร็วเกินกำหนด",
    timeline_suddenly_break: "เบรคกะทันหัน",
    timeline_vehicle_stop_with_engine_on: "จอดรถติดเครื่อง",
    tire: "ยาง",
    voluntary_motor_insurance: "ประกันภัยรถยนต์ภาคสมัครใจ",
    Yes: "ใช่",
  },
  vehicleType: {
    backhoe: "รถแบคโฮ",
    bulk_cargo: "เรือสินค้าเทกอง",
    bus: "รถเมล์",
    car_6wheelers_trailer: "รถ 6 ล้อ เทรลเลอร์",
    caravan_10wheelers: "รถพ่วง 10 ล้อ",
    cargo_ship: "เรือ",
    cash_transporter_cmc01: "รถขนส่งเงิน - CMC 01",
    cash_transporter_cmc02: "รถขนส่งเงิน - CMC 02",
    cash_transporter_g45: "รถขนส่งเงิน - G4S",
    cash_transporter_lrd130: "รถขนส่งเงิน - LRD130",
    cash_transporter_test01: "รถขนส่งเงิน - Test 01",
    cement_bags_transporter_10wheelers: "รถขนส่งปูนถุง 10 ล้อ",
    cement_powder_banana_shaped_trailer: "รถปูนผง กล้วยหอม",
    cement_powder_full_trailer: "รถปูนผง พ่วงเต้า",
    concrete_pump: "รถปั๊มคอนกรีต",
    container_truck_10wheelers: "รถตู้คอนเทนเนอร์ 10 ล้อ",
    container_truck_6wheelers: "รถตู้คอนเทนเนอร์ 6 ล้อ",
    drive_10wheelers: "รถ 10 ล้อ",
    drive_12wheelers: "รถ 12 ล้อ",
    drive_18wheelers: "รถ 18 ล้อ",
    drive_3wheelers: "รถ 3 ล้อ",
    drive_6wheelers: "รถ 6 ล้อ",
    dump_truck: "รถดั๊ม",
    express_4wheelers: "4 ล้อ เอ็กเพรส",
    fjsldk: "fjsldk",
    flatbed_car: "รถพื้นเรียบ",
    forage_transporter_10wheelers: "รถขนอาหารสัตว์ 10 ล้อ",
    forage_transporter_12wheelers: "รถขนอาหารสัตว์ 12 ล้อ",
    forage_transporter_18wheelers: "รถขนอาหารสัตว์ 18 ล้อ",
    forage_transporter_22wheelers: "รถขนอาหารสัตว์ 22 ล้อ",
    forage_transporter_trailer: "รถขนอาหารสัตว์ เทรลเลอร์",
    forklift: "รถยก",
    freeze_truck_4wheelers: "รถตู้เย็น 4 ล้อ",
    freeze_truck_6wheelers: "รถตู้เย็น 6 ล้อ",
    harvestar: "รถเก็บเกี่ยว",
    hazardous_materials_10wheelers: "รถบรรทุกวัตถุอันตราย 10 ล้อ",
    hazardous_materials_trailer: "รถบรรทุกวัตถุอันตราย เทรลเลอร์",
    high_4wheelers: "4 ล้อสูง",
    isuzu: "อีซูซุ",
    jumbo_4wheelers: "4 ล้อจัมโบ้",
    mini_4wheelers: "4 ล้อ มินิ",
    mix_trailer: "เทรเลอร์มิกซ์",
    motorcycle: "รถจักรยานยนต์",
    ngv_10wheelers: "NGV 10 ล้อ",
    pickup_4wheelers: "รถกระบะ 4 ล้อ",
    rmx_truck_10wheelers: "รถบรรทุก RMX 10 ล้อ",
    rmx_truck_4wheelers: "รถบรรทุก RMX 4 ล้อ",
    rmx_truck_6wheelers: "รถบรรทุก RMX 6 ล้อ",
    sedan: "รถเก๋ง",
    tail: "หาง",
    taxi: "รถแท็กซี่",
    tractor: "รถไถ",
    type_test: "ทดสอบ",
    van: "รถตู้",
    van_dont_send_department: "รถตู้(ไม่ส่งกรม)",
    van_tpt: "รถตู้ TPT",
  },
  w: {
    warningAudioForParkingTimeComplete: "เสียงเตือนจอดครบเวลา",
    warningAudioForNotStoppingInTime: "เสียงเตือนจอดไม่ครบเวลา",
    wrongSpeedAccordingToPolicy: "ความเร็วผิดตามนโยบาย (กม./ชม.)",
    waiting: "รอ",
    watchVideoRealTime: "ดูวิดีโอเรียลไทม์",
    w: "ความเร็วเชิงมุม",
    wait: "รอ",
    w_friday: "ศุกร์",
    w_monday: "จันทร์",
    w_saturday: "เสาร์",
    w_sunday: "อาทิตย์",
    w_thursday: "พฤหัสบดี",
    w_tuesday: "อังคาร",
    w_wednesday: "พุธ",
    waitAssessment: "รอประเมิน",
    waitForEmailChecking: "รอการบืน Email",
    waitingConfirm: "รอยืนยัน",
    waitingForJob: "รองาน",
    warrantyEnd: "สิ้นสุดการรับประกัน",
    week: "สัปดาห์",
    weekly: "รายสัปดาห์",
    weWillContactYouAtTheTelephoneNumberProvided:
      "เราจะติดต่อคุณตามหมายเลขโทรศัพท์ที่แจ้งไว้",
    wheel: "จำนวนวงล้อ",
    whenEventOccurred: "ลักษณะการแจ้งเตือน",
    whichMayHaveToContactTheAppointmentLocationAgain:
      "โดยอาจจะมีการติดต่อสถานที่นัดอีกครั้งได้",
    wigth: "กว้าง",
    workingTime: "เวลาวิ่งงาน",
    workingTimeActual: "เวลาวิ่งงานจริง",
    workOverTime: "ปฏิบัติงานเกินช่วงเวลา",
    wifiSetting: "ตั้งค่า Wifi",
    wifiConnectionSetting: "ตั้งค่าการเชื่อมต่อ Wifi",
    wifiSSID: "Wifi SSID",
    windshield_viper: "ที่ปัดน้ำฝน",
    withdrawfuelcredit: "จำนวนเบิกเครดิต(ลิตร)",
    widthMain: "ความกว้างหลัก",
    widthSub: "ความกว้างรอง",
  },
  weight_uom: {
    g: "กรัม",
    kg: "กิโลกรัม",
    weight_uom: "หน่วยของน้ำหนัก",
    weight_uom_require: "กรุณาเลือกหน่วยของน้ำหนัก",
  },
  x: {},
  y: {
    year: "ปี",
    yearly: "รายปี",
    yes: "ใช่",
    youCanOonlyUploadExcelFile: "คุณสามารถอัปโหลดไฟล์ EXCEL ได้เท่านั้น",
    youCanOonlyUploadPdfFile: "คุณสามารถอัปโหลดไฟล์ PDF ได้เท่านั้น",
    youCanOnlyUploadPNGorPDFfiles:
      "คุณสามารถอัปโหลดไฟล์ PNG, JPG, JPEG, PDF ได้เท่านั้น",
  },
  z: {
    zone: "พื้นที่",
    zoneName: "ชื่อพื้นที่",
    zoneNameIsRequired: "กรุณากรอกชื่อพื้นที่",
    zoomIn: "ขยายเข้า",
    zoomInForMoreInfo: "ซูมเข้าเพื่อใช้งานฟีเจอร์",
    zoomOut: "ขยายออก",
  },
  concreteDeliveryStatus: {
    loadDuration: "ระยะเวลาโหลดปูน",
    loadFinishTime: "จบโหลดปูน",
    truckWashTime: "เวลาล้างรถ",
    plantDepartureTime: "เวลาออกจากแพลนต์",
    distance: "ระยะทาง",
    siteArrivalTime: "เวลาถึงไซต์งาน",
    waitDurationBeforeUnload: "ระยะเวลาก่อนเริ่มเทปูน",
    unloadDuration: "ระยะเวลาเทปูน",
    unloadFinishTime: "จบเทปูน",
    siteDepartureTime: "เวลาออกจากไซต์งาน",
    noSiteEntry: "ไม่เข้าไซต์ลูกค้า",
    dischargeNotDetected: "ไม่พบการเทปูนออก",
    noSiteDeparture: "ไม่ออกจากไซต์ลูกค้า",
    noReturnTrip: "ไม่กลับเข้าแพล้นต์",
    waitingForLoading: "กำลังรอโหลด",
    connectionLost: "ขาดการติดต่อ",
  },
  loadTimeStatus: {
    loadStartTime: "เริ่มโหลดปูน",
    loadFinishTime: "จบโหลดปูน",
    quantity: "ปริมาณ",
  },
  plantMoveOutStatus: {
    plantDepartureTime: "เวลาออกจากแพลนต์",
    etdToPlant: "เวลาที่ควรออกจากแพลนต์",
  },
  onTheWayStatus: {
    speedLimit: "ความเร็วเกินกำหนด",
    late: "รถเข้าช้ากว่ากำหนด",
  },
  custMoveInStatus: {
    siteArrivalTime: "เวลาถึงไซต์งาน",
    etaToSite: "เวลาที่ควรเข้าไซต์",
  },
  unloadTimeStatus: {
    unloadStartTime: "เริ่มเทปูน",
    unloadFinishTime: "จบเทปูน",
    duration: "ระยะเวลา",
    quantity: "ปริมาณ",
    total: "รวม",
    unauthorizedDischarge: "พบการเทปูนออกนอกไซต์ลูกค้า",
  },
  custMoveOutStatus: {
    siteDepartureTime: "เวลาออกจากไซต์งาน",
    etdToSite: "เวลาที่ควรออกจากไซต์",
  },
  manageOTP: {
    phoneNumber: "หมายเลขโทรศัพท์",
    companyName: "ชื่อบรืษัท",
    searchCompany: "ค้นหาบริษัท",
    totalVehicle: "จำนวนพาหนะ",
    matchPhoneNoWithVehicle: "ผูกทะเบียนรถกับเบอร์โทรศัพท์",
    pleaseFieldPhoneNumber: "กรุณาระบุเบอร์โทรศัพท์",
    pleaseField10PhoneNumber: "กรุณาระบุหมายเลขโทรศัพท์ 10 หลัก",
    pleaseFieldCorrectPhoneNumber: "กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง",
    updateAt: "อัปเดตล่าสุด",
    manageOTP: "การจัดการOTP",
  },
  manageFuelRate: {
    consumptionWhileParking: "อัตราสิ้นเปลืองขณะจอดติดเครื่อง(ลิตร ต่อ ชม.)",
    consumptionWhileRunning: "อัตราสิ้นเปลืองขณะวิ่ง(กม. ต่อ ลิตร)",
    vehicleModel: "รุ่น",
    vehicleGroupNumber: "กลุ่มเบอร์รถ",
    manageFuelRate: "การจัดการอัตราน้ำมัน",
  },
  sdcardStatus: {
    normal: "ปกติ",
    broken: "เสีย",
    none: "-",
  },
  iconsiam: {
    route: "เส้นทาง",
    name_th: "ชื่อเส้นทาง (ไทย)",
    name_en: "ชื่อเส้นทาง (อังกฤษ)",
    name_ch: "ชื่อเส้นทาง (จีน)",
    start_location: "ต้นทาง",
    end_location: "ปลายทาง 1",
    end_location_2: "ปลายทาง 2",
    distance: "ระยะทาง (กม.)",
    duration: "ระยะเวลาต่อรอบ (นาที)",
    duration_point: "ระยะเวลาไปยังจุดถัดไป (นาที)",
    parking: "ระยะเวลาจอดท่า (นาที)",
    assign_vehicle: "กำหนดพาหนะ",
    service_time: "ตารางเดินเรือ",
    pier: "ท่าเรือ",
    schedule: "ช่วงเวลา",
    color: "สีเส้นทาง",
    icon: "ไอคอน",
    priority: "ลำดับ",
    active: "เปิดใช้งาน",
    unavailable: "ซ่อมบำรุง",
    unavailable_msg_th: "ข้อความซ่อมบำรุง (ไทย)",
    unavailable_msg_en: "ข้อความซ่อมบำรุง (อังกฤษ)",
    add_vehicle: "เพิ่มเรือ",
    boat_type: "ประเภทเรือ",
    previous: "ก่อนหน้า",
    min_value: "ต้องมากกว่า 0",
  },
  replayNewV3: {
    speed_over_limit: "ความเร็วเกินกำหนด",
    timeline_aggressive_acceleration: "ออกตัวกระชาก",
    boom_ready: "ยกบูม",
    turn_left_signal_on: "เปิดไฟเลี้ยวซ้าย",
    turn_right_signal_on: "เปิดไฟเลี้ยวขวา",
    honk: "บีบแตร",
    low_beam_on: "เปิดไฟต่ำ",
    high_beam_on: "เปิดไฟสูง",
    windshield_wipers_on: "เปิดที่ปัดน้ำฝน",
    windshield_wipers: "ปัดน้ำฝน",
    reversing_light_on: "เปิดไฟถอยหลัง",
    hit_the_brake: "เหยียบเบรค",
    hard_brake: "เบรคแรง",
    discharge_concrete: "หมุนเทปูน",
    pto: "เปิด PTO/ประตู",
    sliding_card: "รูดใบขับขี่",
    not_sliding_card: "ไม่รูดใบขับขี่",
    moving: "เคลื่อนตัว",
    idling: "idling",
    stop: "หยุด",
    Factory: "โรงงาน",
    Plant: "โหลดของ",
    Fuel: "ปั้มน้ำมัน",
    Maintenance: "ศูนย์บริการ",
    Parking: "จุดพัก/จุดจอด",
    NoParking: "ห้ามจอด",
    BusStop: "ป้ายรถเมล์",
    PickUpPoint: "จุดรับส่งพนักงาน",
    Site: "จุดส่งของ",
    PlantOnSite: "แพลนท์ออนไซต์",
    ManyParking: "สถานที่ > 1",
    Temporary: "สถานที่ชั่วคราว",
    PreparePoint: "จุดพร้อม",
    OverSpeed: "ความเร็วเกิน",
    SOS: "ฉุกเฉิน",
    Hrashbreak: "เบรกรุนแรง",
    PTO: "PTO",
    Moving: "เคลื่อนที่/ติดเครื่อง",
    Idling: "จอดไม่ดับเครื่อง",
    Stop: "จอดดับ/เครื่อง",
    SwipingCard: "รูดใบขับขี่",
    NotSwipingCard: "ไม่รูดใบขับขี่",
    OutsideTheArea: "จุดนอกจุดจอด",
    Boom: "BOOM",
    DischargeConcreate: "หมุนเทปูน",
    TurnLeft: "เลี้ยวซ้าย",
    TurnRight: "เลี้ยวขวา",
    Horn: "บีบแตร",
    LowBeam: "ไฟต่ำ",
    HighBeam: "ไฟสูง",
    GPSUnavailable: "พิกัดไม่แม่นยำ",
    WindShield: "เปิดที่ปัดน้ำฝน",
    ReversingLight: "ไฟถอยหลัง",
    Iding: "จอดไม่ดับเครื่อง",
    Iding: "จอดไม่ดับเครื่อง",
    BOOM: "BOOM",
    DischargeConcrete: "หมุนเทปูน",
    SpeedGraph: "กราฟความเร็ว",
    FuelGraph: "กราฟเชื้อเพลิง",
    TempGraph: "กราฟอุณหภูมิ",
    engineStatus: "สถานะเครื่องยนต์",
    parking_area: "จุดจอด",
    speed_limit: "ความเร็ว",
    drive_surge: "ออกตัวกระชาก",
    Break: "เบรค",
    engineOn: "ติดเครื่อง",
    engineOff: "ดับเครื่อง",
    running: "วิ่ง",
    stopped: "จอดรถ",
    accAlarm: "ความเร่งผิดปกติ/ออกตัวกระชาก",
    slidingCard: "รูดบัตร",
  },
  manageDashboardMonitoring: {
    DisplayName: "ชื่อ Display",
    Channel: "Channel",
    CameraNumber: "จำนวนกล้อง",
    GroupName: "ชื่อกลุ่มพาหนะ",
    PlateList: "ทะเบียนรถ",
    manageDashboardMonitoring: "ตั้งค่ามอนิเตอร์วีดีโอเรียลไทม์",
    dashboardMonitoring: "มอนิเตอร์วีดีโอเรียลไทม์ (Realtime Monitoring)",
  },
  orc: {
    configFuelcredit: "ตั้งค่าเครดิตน้ำมัน",
    initFuelCredit: "ค่าเครดิตเริ่มต้น",
    withdrawFuelLimit: "จำนวนเบิกสูงสุด",
    depositReason: "เหตุผลในการเพิ่มเครดิตน้ำมัน",
  },
  cameraPosition: {
    front: "กล้องหน้ารถ",
    back: "กล้องในรถ",
    left: "กล้องด้านซ้าย",
    right: "กล้องด้านขวา",
  },
};

export { th };
