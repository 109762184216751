import { createTypes, createAction } from 'lib/action'

// *remove lists
const REMOVE_LISTS = `REMOVE_LISTS`

// *ค้นหาข้อมูล
const MONITOR_GPS_TABLE_LOG = createTypes('monitorGPSTableLog', 'load')

// *แสดงผลข้อมูล chart
const MONITOR_GPS_CHART_LOG = createTypes('monitorGPSChartLog', 'load')

// *ดึงข้อมูลบริษัทสำหรับ filter
const MONITOR_GPS_COMPANY_LIST = createTypes('monitorGPSCompanyList', 'load')

// *remove lists
function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

// *ดึงข้อมูล table log
const monitorGPSTableLog = {
  request: (data) => createAction(MONITOR_GPS_TABLE_LOG.REQUEST, { data }),
  success: (lists) =>
    createAction(MONITOR_GPS_TABLE_LOG.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_GPS_TABLE_LOG.FAITLURE),
}

// *ดึงข้อมูล chart log
const monitorGPSChartLog = {
  request: (data) => createAction(MONITOR_GPS_CHART_LOG.REQUEST, { data }),
  success: (lists) =>
    createAction(MONITOR_GPS_CHART_LOG.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_GPS_CHART_LOG.FAITLURE),
}

// *ดึงข้อมูลบริษัทสำหรับ filter
const monitorGPSCompanyList = {
  request: (data) => createAction(MONITOR_GPS_COMPANY_LIST.REQUEST, { data }),
  success: (lists) =>
    createAction(MONITOR_GPS_COMPANY_LIST.SUCCESS, { lists }),
  failure: () => createAction(MONITOR_GPS_COMPANY_LIST.FAITLURE),
}

export {
  MONITOR_GPS_TABLE_LOG,
  monitorGPSTableLog,
  MONITOR_GPS_CHART_LOG,
  monitorGPSChartLog,
  MONITOR_GPS_COMPANY_LIST,
  monitorGPSCompanyList,
  REMOVE_LISTS,
  removeLists,
}
