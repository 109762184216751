import React, { Component } from "react";
import { Modal, Button, Select, Form, Row, Col } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export default class AutoplayModal extends Component {
  vehicleStatus = [
    "running",
    "stop-with-engine-on",
    "stop-with-engine-off",
    "connection-error",
  ];
  windowNum = [1, 4, 9];
  isuCam = ["กล้องนอก", "กล้องใน", "อื่นๆ"];
  hntJ40Cam = ["กล้องนอก", "กล้องใน"];
  generateSelectOption = () => {
    return this.vehicleStatus.map((v) => {
      return <Select.Option value={v}>{v}</Select.Option>;
    });
  };
  generateSelectWindownNum = () => {
    return this.windowNum.map((v) => {
      return <Select.Option value={v}>{v}</Select.Option>;
    });
  };
  generateSelectIsuCam = () => {
    return this.isuCam.map((v, index) => {
      return <Select.Option value={index + 1}>{v}</Select.Option>;
    });
  };
  generateSelectHntCam = () => {
    return this.hntJ40Cam.map((v, index) => {
      return <Select.Option value={index}>{v}</Select.Option>;
    });
  };
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onOk={this.props.handleAutoPlayModalOk}
        onCancel={this.props.handleCancel}
        cancelText="เล่นต่อ"
        okText="เริ่มเล่น"
        footer={[
          <Button type="link" onClick={this.props.handleContinue}>
            เล่นต่อ
          </Button>,
          <Button
            type="primary"
            loading={this.props.loading}
            onClick={this.props.handleAutoPlayModalOk}
          >
            เริ่มเล่น
          </Button>,
        ]}
      >
        <Form {...formItemLayout}>
          <Form.Item label="สถานะรถ">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={this.props.autoplayStatus}
              onChange={(v) => {
                this.props.handleSetState("autoplay_status", v);
              }}
            >
              {this.generateSelectOption()}
            </Select>
          </Form.Item>
          <Form.Item label="จำนวนจอ">
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              value={this.props.autoplaySize}
              onChange={(v) => {
                this.props.handleSetState("autoplay_size", v);
              }}
            >
              {this.generateSelectWindownNum()}
            </Select>
          </Form.Item>

          <Form.Item label="เลือกกล้อง ISU">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={this.props.autoplayIsuCam}
              onChange={(v) => {
                this.props.handleSetState("autoplay_isu_cam", v);
              }}
            >
              {this.generateSelectIsuCam()}
            </Select>
          </Form.Item>

          <Form.Item label="เลือกกล้อง HNT">
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              value={this.props.autoplayHntCam}
              onChange={(v) => {
                this.props.handleSetState("autoplay_hnt_cam", v);
              }}
            >
              {this.generateSelectHntCam()}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
