import React, { Component } from "react";
import { Table, Tooltip, Icon, Menu, SubMenu, Checkbox } from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { renderComponent } from "recompose";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tagRed = {
  backgroundColor: "#F5F50E",
  color: "black",
  fontSize: "11px",
  lineHeight: "15px",
  fontWeight: "600",
};

class ListVehicle extends Component {
  state = {
    list_vehicle_id: [],
    visible: false,
    orderBy: "plate_no",
    orderType: "asc",
    btnHover: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";
    //console.log("sort:", sorter)

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "plate_no",
        orderType: "desc",
      });
      orderBy = "plate_no";
      orderType = "desc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadListVehicleHoneytoast(
      {
        list_vehicle_id: this.state.list_vehicle_id,
        orderBy: orderBy,
        orderType: orderType,
      },
      true
    );
  };
  handleHover = (key, value) => {
    let temp = [`btn-menu-${key}`];
    this.setState({ btnHover: temp });
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      this.props.loadListVehicleHoneytoast(null, true);
    }, 1000 * 60);

    let url = new URL(window.location.href);
    let imei = url.searchParams.get("imei");
    //let vhID = url.searchParams.get("vehicle_id");
    if (imei === null) {
      this.props.loadListVehicleHoneytoast(this.state, true);
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleCheckDisableSelectAll = (camera, vehicleId, hwid) => {
    let disable = true;
    camera.map((x) => {
      disable =
        disable && this.props.renderFrame.includes(`${hwid + "_" + x.index}`);
    });
    return disable;
  };
  handleClickRow = (record) => {
    let imei = record.hardware_id;
    if (record.device_types_id === 40) {
      if (this.props.vehicle_select.includes(imei)) {
        this.props.loadVehicleSelect(imei, 1);
      } else {
        this.props.handleSelectAllIsingCamera(
          record.camera_channel,
          record.id,
          imei
        );
      }
    } else {
      this.props.loadVehicleSelect(imei);
    }
  };
  render() {
    const { vehicle_select } = this.props;
    let state = this.state;
    const columns = [
      {
        title: <span style={{ fontSize: "14px" }}>#</span>,
        dataIndex: "row_number",
        width: "15%",
        align: "center",
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.handleClickRow(record);
          },
        }),
        render(text, record, index) {
          return {
            props: {
              style:
                vehicle_select.indexOf(record.hardware_id) != -1
                  ? tagRed
                  : columnStyle,
            },
            children: <div style={{ fontSize: "14px" }}>{text}</div>,
          };
        },
      },
      {
        title: (
          <span style={{ fontSize: "14px" }}>
            <LangContext.Consumer>
              {(i18n) => i18n.p.plateNo}
            </LangContext.Consumer>
          </span>
        ),
        dataIndex: "plate_no",
        width: "60%",
        key: "plate_no",
        sorter: false,
        onCell: (record, rowIndex) => ({
          onClick: () => {
            this.handleClickRow(record);
          },
        }),
        render(text, record, index) {
          return {
            props: {
              style:
                vehicle_select.indexOf(record.hardware_id) != -1
                  ? tagRed
                  : columnStyle,
            },
            children: <div style={{ fontSize: "12px" }}>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip
                title={i18n.v.vehicleStatus}
                placement="left"
                style={{ fontSize: "14px" }}
              >
                <span style={{ fontSize: "14px" }}>{i18n.s.status}</span>
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        width: "25%",
        dataIndex: "vehicle_status",
        className: "column-text-center",
        // ellipsis: true,
        sorter: (a, b) => a.vehicle_status.length - b.vehicle_status.length,
        render: (text, record, index) => {
          let color = "";
          let tooltipMessage = "";
          let camera = record.camera_channel;
          let vehicleId = record.id;
          let hwid = record.hardware_id;
          let disableSelectAll = this.handleCheckDisableSelectAll(
            camera,
            vehicleId,
            hwid
          );
          if (record.vehicle_status == "running") {
            color = "#00BF06";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.r.running}
              </LangContext.Consumer>
            );
          } else if (record.vehicle_status == "stop-with-engine-on") {
            color = "#ffd619";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            );
          } else if (record.vehicle_status == "stop-with-engine-off") {
            color = "#c91c1c";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.e.engineOff}
              </LangContext.Consumer>
            );
          } else if (record.vehicle_status == "connection-error") {
            color = "#848484";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.l.lostConnectio}
              </LangContext.Consumer>
            );
          }
          return {
            props: {
              style:
                vehicle_select.indexOf(record.hardware_id) != -1
                  ? tagRed
                  : columnStyle,
            },
            children:
              record.alias === "ISU" ? (
                <Menu
                  expandIcon={<></>}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    height: "35px",
                  }}
                  openKeys={state.btnHover}
                  mode="vertical"
                >
                  <Menu.SubMenu
                    key={`btn-menu-${index}`}
                    title={
                      <Tooltip
                        title={tooltipMessage}
                        placement="left"
                        style={{ margin: "0px" }}
                      >
                        <div
                          style={{
                            verticalAlign: "top",
                            display: "inline-block",
                            textAlign: "center",
                            lineHeight: "15px",
                          }}
                        >
                          <Icon
                            type="car"
                            theme="twoTone"
                            twoToneColor={color}
                            style={{
                              fontSize: "16px",
                              lineHeight: "15px",
                              margin: "0px",
                            }}
                          />
                          <span style={{ display: "block", fontSize: "10px" }}>
                            {record.alias}
                          </span>
                        </div>
                      </Tooltip>
                    }
                  >
                    <Menu.Item
                      key={"all"}
                      onClick={() =>
                        this.props.handleSelectAllIsingCamera(
                          camera,
                          vehicleId,
                          hwid
                        )
                      }
                      disabled={disableSelectAll}
                    >
                      <Checkbox
                        disabled={disableSelectAll}
                        checked={disableSelectAll}
                      >
                        เลือกทั้งหมด
                      </Checkbox>
                    </Menu.Item>
                    {camera.map((x) => {
                      let line = x.index;
                      return (
                        <Menu.Item key={line.toString()}>
                          <Checkbox
                            onChange={() =>
                              this.props.loadVehicleSelect(
                                hwid,
                                line,
                                false,
                                true
                              )
                            }
                            checked={this.props.renderFrame.includes(
                              `${hwid}_${line}`
                            )}
                            // disabled={this.state.disabledIndex.includes(`${hwid}_${line}`)}
                          >
                            {x.name}
                          </Checkbox>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                </Menu>
              ) : (
                <Tooltip title={tooltipMessage} placement="left">
                  <div
                    style={{
                      verticalAlign: "top",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    <Icon
                      type="car"
                      theme="twoTone"
                      twoToneColor={color}
                      style={{ fontSize: "16px" }}
                    />
                    <span style={{ display: "block", fontSize: "10px" }}>
                      {record.alias}
                    </span>
                  </div>
                </Tooltip>
              ),
          };
        },
      },
    ];

    return (
      <div style={{ height: `${window.innerHeight - 140}px` }}>
        <Table
          bordered
          // size="small"
          pagination={false}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={this.props.vehicleListHoneytoast}
          loading={this.props.videoLoading}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (event) => {
                this.handleHover(rowIndex, true);
              }, // mouse enter row
              onMouseLeave: (event) => {
                this.setState({ btnHover: [] });
              }, // mouse leave row
            };
          }}
        />
      </div>
    );
  }
}

export default ListVehicle;
