import React, { Component } from "react";
import LangContext, { i18n } from "modules/shared/context/langContext";

import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Transfer,
  Select,
  Divider,
  Input,
  Spin,
  Icon,
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
export default class Forms extends Component {
  state = {
    vehicleList: [],
    vehicleSelect: this.props.vehicleSelected,
    selectedKeys: [],
  };
  generateSlectOption = () => {
    return this.props.displayList.map((v) => {
      return (
        <Select.Option
          key={v.display_id}
          value={v.display_id}
          style={{ background: "#fff !impotant" }}
        >
          {v.display_name}
        </Select.Option>
      );
    });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  formatVehicleSelected = (data) => {
    return data.map((item) => {
      return item.id;
    });
  };

  formatDataFortransfer = (data) => {
    return data.map((item) => {
      return { key: item.id, name: item.plate_no };
    });
  };
  formatDataForUsertransfer = (data) => {
    return data.map((item) => {
      return { key: item.id, name: item.username };
    });
  };
  getNameDisplay = (id) => {
    let obj = this.props.displayList.find((item) => item.display_id === id)
      ? this.props.displayList.find((item) => item.display_id === id)
      : "";
    return obj.display_name;
  };
  handleOnsubmit = (v) => {
    this.props.onSubmitForm(v);
  };

  confirmDeleteDisplay = (submit) => {
    Modal.confirm({
      title: "คุณต้องการลบรูปแบบ ?",
      content:
        "โปรดตรวจสอบข้อมูลให้แน่ใจก่อนทำการลบข้อมูล หากลบไปแล้วจะไม่สาทารถทำการกู้คืนกลับมาได้อีก",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      okType: "danger",
      icon: <Icon type="info-circle" style={{ color: "#D3D3D3" }} />,
      onOk() {
        submit();
      },
    });
  };
  confirmCreateUpdateDisplay = (submit) => {
    Modal.confirm({
      title: "คุณต้องการบันทึกซ้ำ ?",
      content:
        "โปรดตรวจสอบข้อมูลให้แน่ใจก่อนทำการลบข้อมูล หากลบไปแล้วจะไม่สาทารถทำการกู้คืนกลับมาได้อีก",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk() {
        submit();
      },
    });
  };
  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          display_select: this.props.displaySelect,
          display_name: this.getNameDisplay(this.props.displaySelect),
          vehicle_list: this.formatVehicleSelected(this.props.vehicleSelected),
          user_id: [this.props.userId],
        }}
        validate={(values) => {
          let errors = {};

          return errors;
        }}
        validationSchema={yup.object().shape({})}
        onSubmit={(values) => {
          // const loading = true;
          // this.props.onOk(values, loading);
          this.handleOnsubmit(values);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleFocus,
          setFieldValue,
          isValidating,
        }) => (
          <Modal
            style={{
              top: 20,
              maxHeight: "90vh",
              overflow: "auto",
              scrollbarWidth: "none",
            }}
            title={
              <LangContext.Consumer>
                {(i18n) => i18n.m.manageTemplate}
              </LangContext.Consumer>
            }
            visible={this.props.visible}
            onOk={handleSubmit}
            onCancel={this.props.onCancel}
            okText={
              <LangContext.Consumer>
                {(i18n) => i18n.o.okay}
              </LangContext.Consumer>
            }
            cancelText={
              <LangContext.Consumer>
                {(i18n) => i18n.c.cancel}
              </LangContext.Consumer>
            }
            width={700}
            footer={[
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="link"
                  loading={this.props.formsLoading}
                  onClick={() =>
                    this.confirmDeleteDisplay(() => {
                      this.props.deleteDisplay();
                    })
                  }
                  style={{ color: "#D3D3D3" }}
                >
                  ลบรูปแบบ
                </Button>
                <Button
                  type="primary"
                  loading={this.props.formsLoading}
                  onClick={() =>
                    this.confirmCreateUpdateDisplay(() => {
                      handleSubmit();
                    })
                  }
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.okay}
                  </LangContext.Consumer>
                </Button>
              </div>,
            ]}
          >
            <Row type="flex" justify="center">
              <Form onSubmit={handleSubmit}>
                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.selectTemplate}
                    </LangContext.Consumer>
                  }
                >
                  <Select
                    showSearch
                    defaultValue={-99}
                    style={{ width: "100%", marginRight: "5px" }}
                    onChange={(v) => {
                      this.props.handleDisplaySelect(v);
                      setFieldValue("display_select", v);
                    }}
                    size="default"
                    dropdownRender={this.genrateDropdownRender}
                    value={values.display_select}
                  >
                    <Select.Option key={-99} value={-99}>
                      กำหนดเอง
                    </Select.Option>
                    {this.generateSlectOption()}
                  </Select>
                </Form.Item>
                <Divider />
                <Form.Item
                  validateStatus={
                    touched.display_name && errors.display_name && "error"
                  }
                  help={touched.display_name && errors.display_name}
                  label="ชื่อแบบ"
                >
                  <Input
                    name="display_name"
                    value={values.display_name}
                    onChange={(e) => {
                      setFieldValue("display_name", e.target.value);
                    }}
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  label="กำหนดสิทธิ์"
                  validateStatus={touched.user_id && errors.user_id && "error"}
                  help={touched.user_id && errors.user_id}
                >
                  <Transfer
                    titles={[
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemLeft}
                      </LangContext.Consumer>,
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemSelected}
                      </LangContext.Consumer>,
                    ]}
                    dataSource={this.formatDataForUsertransfer(
                      this.props.userList
                    )}
                    showSearch
                    listStyle={{
                      width: 250,
                      height: 300,
                    }}
                    selectedKeys={this.state.selectedKeys}
                    operations={[">>", "<<"]}
                    targetKeys={values.user_id}
                    onSelectChange={this.handleSelectChange}
                    onChange={(value) => setFieldValue("user_id", value)}
                    render={(item) => `${item.name}`}
                    footer={this.renderFooter}
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicle}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.list_vehicle_id && errors.list_vehicle_id && "error"
                  }
                  help={touched.list_vehicle_id && errors.list_vehicle_id}
                >
                  <Transfer
                    titles={[
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemLeft}
                      </LangContext.Consumer>,
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemSelected}
                      </LangContext.Consumer>,
                    ]}
                    dataSource={this.formatDataFortransfer(
                      this.props.vehicleList
                    )}
                    showSearch
                    listStyle={{
                      width: 250,
                      height: 300,
                    }}
                    selectedKeys={this.state.selectedKeys}
                    operations={[">>", "<<"]}
                    targetKeys={values.vehicle_list}
                    onSelectChange={this.handleSelectChange}
                    onChange={(value) => setFieldValue("vehicle_list", value)}
                    render={(item) => `${item.name}`}
                    footer={this.renderFooter}
                    disabled
                  />
                </Form.Item>
                {/* <Divider /> */}
                {/* <Form.Item
                    label="ตั้งค่ามุมกล้อง"
                    required
                    validateStatus={
                      touched.camera && errors.camera && "warning"
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 20 }}
                    help={touched.camera && errors.camera}
                  >
                    <Select
                      // defaultValue={["-"]}
                      // style={{ width: 100 }}
                      value={values.camera}
                      onChange={(v) => {
                        setFieldValue("camera", v);
                      }}
                    >
                      <Select.Option key="0" value="Out">
                        กล้องนอก
                      </Select.Option>
                      <Select.Option key="1" value="In">
                        กล้องใน
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Divider /> */}
              </Form>
            </Row>
          </Modal>
        )}
      />
    );
  }
}
