import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_LARAVEL_API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

const apiRequest = async (method, url, params, callback) => {
  try {
    const res = await axiosClient[method](url, params);
    callback(200, res);
  } catch (error) {
    console.error("API Error:", error);
  }
};

export async function loadVehicleGroup(params, callback) {
  await apiRequest(
    "post",
    "/vehicle-group/vehicle-group-by-company",
    params,
    callback
  );
}

export async function loadVehicle(params, callback) {
  await apiRequest("post", "/vehicle/vehicle-by-company", params, callback);
}

export async function loadVehicleByVehicleGroup(params, callback) {
  await apiRequest(
    "post",
    "/vehicle/vehicle-by-vehicle-group",
    params,
    callback
  );
}
