// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Modal, Row, Col } from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'

export class IconDescModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      handleCloseIconDetailModal,
      icon_detail_modal,
      color_status,
    } = this.props

    let data = [
      {
        desc: (
          <Row style={{ marginTop: '-25px' }}>
            <Col span={5}>
              <h4>GPS</h4>
            </Col>
            <Col span={18} offset={1}>
              {i18n[this.props.language].g.gpsPerformanceDesGPS}
            </Col>
          </Row>
        ),
      },
    ]

    color_status.map((item, index) => {
      data.push({
        desc: (
          <Row style={{}}>
            <Col span={5}>
              <div
                style={{
                  width: '23px',
                  height: '23px',
                  backgroundColor: item.color,
                  borderRadius: '6px',
                }}
              ></div>
            </Col>
            <Col span={18} offset={1}>
              <span>{`${item.title}`}</span>
            </Col>
          </Row>
        ),
      })
    })

    return (
      <Modal
        style={{ top: 20 }}
        title={i18n[this.props.language].i.iconDesc}
        visible={icon_detail_modal}
        onOk={handleCloseIconDetailModal}
        onCancel={handleCloseIconDetailModal}
        okButtonProps={{ style: { display: 'none' } }}
        okText={null}
        cancelText={i18n[this.props.language].c.cancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.desc} />
            </List.Item>
          )}
        />
      </Modal>
    )
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {},
)(IconDescModal)
