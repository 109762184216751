import React, { useState, useEffect } from "react";
import { Button, Row, Col, Icon, Input, Form, Transfer, Select } from "antd";
import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  loadVehicleByVehicleGroup,
  loadVehicleGroup,
} from "../fetchAPI/apiClient";
import LangContext, { i18n } from "modules/shared/context/langContext";

const lineNotiStyle = {
  background: "#3F87E4 0% 0% no-repeat padding-box",
  borderRadius: 10,
  width: "100%",
  color: "white",
  marginTop: 13,
  fontSize: "11px",
};

const errorStyle = {
  color: "red",
  position: "absolute",
  fontSize: "11px",
  marginTop: "5px",
  marginBottom: "5px",
};

const deleteBtnStyle = {
  border: "1px solid #FF0000",
  borderRadius: 10,
  width: "100%",
  color: "#FF0000",
  marginTop: 13,
  fontSize: "11px",
};

const Option = Select.Option;

const LineToken = ({ vehicles, data, onSubmit, auth }) => {
  const [setting, setData] = useState([]);
  const [vehicleGroup, setVehicleGroup] = useState([]);
  const [vehicleSource, setVehicleSource] = useState([]);

  useEffect(() => {
    loadVehicleGroup(
      { company_id: auth.profile.company_id },
      (statusCode, response) => {
        setVehicleGroup(response.data.data);
      }
    );
  }, []);

  useEffect(() => {
    let updateData = data.map((obj) => {
      return {
        ...obj,
        vehicleGroup: "",
        vehicleSource: vehicles,
      };
    });

    setData(updateData);
  }, [data]);

  useEffect(() => {
    setVehicleSource(vehicles);
  }, [vehicles]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            setting: setting,
            removeId: [],
          }}
          validationSchema={yup.object().shape({
            setting: yup.array().of(
              yup.object().shape({
                line_token: yup.string().required("กรุณาระบุ Line Token"),
                //vehicle_id: yup.array().min(1).required("กรุณาระบุพาหนะ")
              })
            ),
          })}
          onSubmit={(values) => {
            onSubmit(
              values.setting.map((item) => {
                return {
                  id: item.id,
                  line_token: item.line_token,
                  vehicle_id: item.vehicle_id,
                };
              }),
              values.removeId
            );
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {/* {JSON.stringify(values)} */}
                <FieldArray
                  name="setting"
                  render={(arrayHelpers) => (
                    <Row
                      gutter={[12, 12]}
                      align="middle"
                      style={{ marginTop: "10px" }}
                    >
                      {values.setting == 0 && (
                        <Col span={4}>
                          <Button
                            onClick={() =>
                              arrayHelpers.push({
                                id: "",
                                line_token: "",
                                vehicle_id: [],
                                vehicleGroup: "",
                                vehicleSource: vehicles,
                              })
                            }
                            style={lineNotiStyle}
                          >
                            <Icon type="plus-circle" />
                            เพิ่ม Line Token
                          </Button>
                        </Col>
                      )}
                      {values.setting.map((val, idx) => (
                        <Col
                          span={12}
                          key={idx.toString()}
                          style={{ marginBottom: "30px" }}
                        >
                          <Row gutter={[24, 24]} type="flex" align="middle">
                            <Col span={12}>
                              <label className="font-11">Line Token:</label>
                              <Field
                                name={`setting[${idx}].line_token`}
                                render={({
                                  field /* { name, value, onChange, onBlur } */,
                                }) => (
                                  <>
                                    <Input
                                      style={{ width: "100%" }}
                                      value={`setting[${idx}].line_token`}
                                      {...field}
                                    />
                                    <ErrorMessage
                                      name={`setting[${idx}].line_token`}
                                      component="div"
                                      style={errorStyle}
                                    />
                                  </>
                                )}
                              />
                            </Col>
                            <Col span={8}>
                              <Button
                                onClick={() =>
                                  arrayHelpers.push({
                                    id: "",
                                    line_token: "",
                                    vehicle_id: [],
                                    vehicleGroup: "",
                                    vehicleSource: vehicles,
                                  })
                                }
                                style={lineNotiStyle}
                              >
                                <Icon type="plus-circle" />
                                เพิ่ม LINE TOKEN
                              </Button>
                            </Col>
                            <Col span={4}>
                              <Button
                                onClick={() => {
                                  let removeList = [
                                    ...values.removeId,
                                    values.setting[idx]["id"],
                                  ];
                                  setFieldValue("removeId", removeList);
                                  arrayHelpers.remove(idx);
                                }}
                                style={deleteBtnStyle}
                              >
                                ลบ
                              </Button>
                            </Col>
                          </Row>
                          <Row
                            gutter={[24, 24]}
                            type="flex"
                            align="middle"
                            style={{ marginTop: "20px" }}
                          >
                            <Col span={24}>
                              <div>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.v.vehicleGroup}
                                </LangContext.Consumer>
                              </div>
                              <Select
                                style={{ width: "96%" }}
                                placeholder="กรุณาเลือก"
                                optionFilterProp="children"
                                value={values.setting[idx]["vehicleGroup"]}
                                onChange={(e) => {
                                  let vehicleArr = [];
                                  loadVehicleByVehicleGroup(
                                    {
                                      company_id: auth.profile.company_id,
                                      vehicle_visibility:
                                        auth.profile.vehicle_visibility,
                                      vehicle_groups_id: e,
                                    },
                                    (statusCode, response) => {
                                      if (response.data.data.length > 0) {
                                        response.data.data.map((item) => {
                                          if (
                                            vehicles
                                              .map((obj) => obj.key)
                                              .includes(item.id)
                                          ) {
                                            vehicleArr.push(item.id);
                                          }
                                        });
                                      }
                                      let checkDisable = vehicles
                                        .filter((item) =>
                                          vehicleArr.includes(item.key)
                                        )
                                        .map((item) => item.key);
                                      let newArr = vehicleSource.map((item) => {
                                        return {
                                          ...item,
                                          disabled: checkDisable.includes(
                                            item.key
                                          )
                                            ? false
                                            : true,
                                        };
                                      });
                                      const sortedArray = newArr.sort(
                                        (item1, item2) => {
                                          if (
                                            item1.disabled === item2.disabled
                                          ) {
                                            return 0;
                                          }
                                          return item1.disabled ? 1 : -1;
                                        }
                                      );
                                      arrayHelpers.replace(idx, {
                                        id: values.setting[idx]["id"],
                                        line_token:
                                          values.setting[idx]["line_token"],
                                        vehicle_id:
                                          values.setting[idx]["vehicle_id"],
                                        vehicleGroup: e,
                                        vehicleSource:
                                          e == "" ? vehicles : sortedArray,
                                      });
                                    }
                                  );
                                }}
                              >
                                <Option value={""}>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.a.all}
                                  </LangContext.Consumer>
                                </Option>
                                {vehicleGroup.map((item) => (
                                  <Option value={item.id}>{item.name}</Option>
                                ))}
                              </Select>
                            </Col>
                            <Col span={24}>
                              <Field
                                name={`setting[${idx}].vehicle_id`}
                                render={({
                                  field /* { name, value, onChange, onBlur } */,
                                }) => (
                                  <>
                                    <Transfer
                                      {...field}
                                      dataSource={
                                        values.setting[idx].vehicleSource
                                      }
                                      titles={["เลือก", "เลือก"]}
                                      // targetKeys={
                                      //   values.setting[idx]["vehicle_id"]
                                      // }
                                      targetKeys={values.setting[idx][
                                        "vehicleSource"
                                      ]
                                        .filter((item) =>
                                          values.setting[idx][
                                            "vehicle_id"
                                          ].includes(item.key)
                                        )
                                        .sort((item1, item2) => {
                                          if (
                                            item1.disabled === item2.disabled
                                          ) {
                                            return 0;
                                          }
                                          return item1.disabled ? 1 : -1;
                                        })
                                        .map((item) => item.key)}
                                      // onChange={(nextTargetKeys) => {
                                      //   arrayHelpers.replace(idx, {
                                      //     id: values.setting[idx]["id"],
                                      //     line_token:
                                      //       values.setting[idx]["line_token"],
                                      //     vehicle_id: nextTargetKeys,
                                      //   });
                                      // }}
                                      onChange={(
                                        nextTargetKeys,
                                        direction,
                                        moveKeys
                                      ) => {
                                        if (direction === "left") {
                                          arrayHelpers.replace(idx, {
                                            id: values.setting[idx]["id"],
                                            line_token:
                                              values.setting[idx]["line_token"],
                                            vehicle_id: nextTargetKeys,
                                            vehicleGroup:
                                              values.setting[idx][
                                                "vehicleGroup"
                                              ],
                                            vehicleSource:
                                              values.setting[idx][
                                                "vehicleSource"
                                              ],
                                          });
                                        } else {
                                          arrayHelpers.replace(idx, {
                                            id: values.setting[idx]["id"],
                                            line_token:
                                              values.setting[idx]["line_token"],
                                            vehicle_id: nextTargetKeys,
                                            vehicleGroup:
                                              values.setting[idx][
                                                "vehicleGroup"
                                              ],
                                            vehicleSource:
                                              values.setting[idx][
                                                "vehicleSource"
                                              ],
                                          });
                                        }
                                      }}
                                      filterOption={(inputValue, item) => {
                                        return (
                                          item.plate_no
                                            .toUpperCase()
                                            .indexOf(
                                              inputValue.toUpperCase()
                                            ) !== -1
                                        );
                                      }}
                                      render={(item) => (
                                        <div className="font-11 inline">
                                          {item.plate_no}
                                        </div>
                                      )}
                                      listStyle={{
                                        width: "45%",
                                        height: 300,
                                        fontSize: "11px",
                                      }}
                                      showSearch
                                    />
                                    {/* <ErrorMessage
                                    name={`setting[${idx}].vehicles`}
                                    component="div"
                                    style={errorStyle}
                                  /> */}
                                  </>
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  )}
                />
                <Row
                  type="flex"
                  gutter={[12, 12]}
                  align="bottom"
                  style={{ marginTop: "50px" }}
                >
                  <Col span={24}>
                    <Button type="primary" htmlType="submit">
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default LineToken;
