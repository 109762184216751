import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadListVehicleHoneytoast(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: { list_vehicle_id, vehicle_status, orderBy, orderType, device_type },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclehoneytoast`,
      {
        vehicle_visibility,
        list_vehicle_id,
        vehicle_status,
        orderBy,
        orderType,
        device_type,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadListVehicleHoneytoast.success(res.data));
  } catch (err) {
    yield put(actions.loadListVehicleHoneytoast.failure());
  }
}

function* loadVehicle(action) {
  const { text, callback } = action.payload;

  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlistvehiclehoneytoast`,
      {
        ...text,
        vehicle_visibility,
        company_id: COMPANY_ID,
        list_vehicle_id: "",
        orderBy: "plate_no",
        orderType: "asc",
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    callback(res.data);
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}
function* loadDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedisplayvideorealtime/display/` +
        text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadDisplay.success(res.data));
  } catch (err) {
    yield put(actions.loadDisplay.failure());
  }
}
function* loadUserCompany(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/getuser/` +
        text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadUserCompany.success(res.data));
  } catch (err) {
    yield put(actions.loadUserCompany.failure());
  }
}

function* loadDisplayDetail(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, callback } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedisplayvideorealtime/detail/` +
        text.display_id +
        "?user_id=" +
        text.user_id,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    callback(res.data);
    yield put(actions.loadDisplayDetail.success(res.data));
  } catch (err) {
    yield put(actions.loadDisplayDetail.failure());
  }
}

function* createDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedisplayvideorealtime/display`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.createDisplay.success(res.data));
  } catch (err) {
    yield put(actions.createDisplay.failure(getErrorMsg(err)));
  }
  generateMsg();
}

function* updateDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedisplayvideorealtime/display`,
      text,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateDisplay.success(res.data));
  } catch (err) {
    yield put(actions.updateDisplay.failure(getErrorMsg(err)));
  }
  generateMsg();
}
function* deleteDisplay(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { text, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.delete,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/managedisplayvideorealtime/display/` +
        text.display_id,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.deleteDisplay.success(res.data));
  } catch (err) {
    yield put(actions.deleteDisplay.failure(getErrorMsg(err)));
  }
  generateMsg();
}
export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST,
      loadListVehicleHoneytoast
    ),
    takeEvery(actions.LOAD_VEHICLE_HONEYTOASTSTREAM.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_DISPLAY.REQUEST, loadDisplay),
    takeEvery(actions.LOAD_USER_COMPANY.REQUEST, loadUserCompany),
    takeEvery(actions.LOAD_DISPLAY_DETAIL.REQUEST, loadDisplayDetail),
    takeEvery(actions.CREATE_DISPLAY.REQUEST, createDisplay),
    takeEvery(actions.UPDATE_DISPLAY.REQUEST, updateDisplay),
    takeEvery(actions.DELETE_DISPLAY.REQUEST, deleteDisplay),
  ]);
}

export {
  loadListVehicleHoneytoast,
  loadVehicle,
  loadDisplay,
  loadDisplayDetail,
  createDisplay,
  updateDisplay,
  deleteDisplay,
};
