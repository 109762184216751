import React, { Component } from "react";
import { Col, Dropdown, Button, Menu, Icon, Checkbox } from "antd";

export default class VehicleDropdown extends Component {
  state = {
    selectIndex: [],
    disabledIndex: [],
    visible: [],
    btnHover: {},
    selectAll: [],
  };

  handleSetCamera = (vehicleId, idx) => (e) => {
    let data = this.props.vehicles.find(
      (x) => parseInt(x.id) === parseInt(vehicleId)
    );
    this.props.handleSelectCamera(data.hardware_id, idx, false, true);
  };

  handleOnClick = (hardware_id, deleted) => {
    this.setState({ btnHover: [] });
    this.props.handleSelectCamera(hardware_id, deleted);
  };
  handleCheckDisableSelectAll = (camera, vehicleId, hwid) => {
    let disable = true;
    camera.map((x) => {
      disable =
        disable && this.state.selectIndex.includes(`${hwid + "_" + x.index}`);
    });
    return disable;
  };
  handleSelecAll = (camera, vehicleId, hwid) => {
    let selectAll = [...this.state.selectAll, hwid];
    this.setState({ ...this.state, selectAll });
    this.props.hadleSelectAllIsingCamera(camera, vehicleId, hwid);
  };
  generateDropdown = (camera, vehicleId, hwid) => {
    let disableSelectAll = this.handleCheckDisableSelectAll(
      camera,
      vehicleId,
      hwid
    );
    return (
      <Menu mode="vertical">
        <Menu.Item
          key={"all"}
          onClick={() =>
            this.props.hadleSelectAllIsingCamera(camera, vehicleId, hwid)
          }
          disabled={disableSelectAll}
        >
          <Checkbox disabled={disableSelectAll} checked={disableSelectAll}>
            เลือกทั้งหมด
          </Checkbox>
        </Menu.Item>
        {camera.map((x) => {
          let line = x.index;
          return (
            <Menu.Item key={line.toString()}>
              <Checkbox
                onChange={this.handleSetCamera(vehicleId, line)}
                checked={this.state.selectIndex.includes(`${hwid}_${line}`)}
                // disabled={this.state.disabledIndex.includes(`${hwid}_${line}`)}
              >
                {x.name}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    let first =
      JSON.stringify(prevProps.vehicles) !==
      JSON.stringify(this.props.vehicles);
    // let second = prevProps.vehicles.length === 1 && this.props.vehicles.length === 1
    // if (second) {
    //     second = prevProps.vehicles[0].hardware_id !== this.props.vehicles[0].hardware_id
    // }

    let second = prevProps.remainFrame != this.props.remainFrame;
    let third =
      JSON.stringify(prevProps.renderFrame) !==
      JSON.stringify(this.props.renderFrame);

    if (first || second || third) {
      let remainFrame = this.props.remainFrame;
      let disabledIndex = [];
      let selectIndex = [];

      // case reset state
      if (this.props.vehicles.length === 0) {
        this.setState({
          selectIndex: [],
          disabledIndex: [],
        });
        return;
      }

      selectIndex = [...selectIndex, ...this.props.renderFrame];

      if (remainFrame == 0) {
        this.props.vehicles.map((x) => {
          for (let i = 0; i < x.camera_channel.length; i++) {
            let line = x.camera_channel[i].index;
            let key = `${x.hardware_id}_${line}`;
            if (!selectIndex.includes(key)) {
              disabledIndex.push(`${key}`);
            }
          }
        });
      }

      this.setState({
        selectIndex: selectIndex,
        disabledIndex: disabledIndex,
      });
    }
  }
  handleMenuClick = (e) => {
    if (e.key === "3") {
      this.setState({ visible: false });
    }
  };

  handleHover = (key, value) => {
    let temp = [];
    temp[key] = value;
    this.setState({ btnHover: temp });
  };
  handleVisibleChange = (flag, idx) => {
    let temp = [];
    temp[idx] = flag;
    this.setState({ visible: temp });
  };
  render() {
    let cameraList = this.props.vehicles;
    return cameraList.map((x, idx) => {
      if (x.camera_channel.length > 0) {
        return (
          //   <Col span={6} key={x.vehicle_code}>
          <Dropdown
            overlay={this.generateDropdown(
              x.camera_channel,
              x.id,
              x.hardware_id
            )}
            onVisibleChange={(flag) => this.handleVisibleChange(flag, idx)}
            visible={this.state.visible[idx]}
          >
            <Button
              style={{
                width: "180px",
                margin: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => this.handleOnClick(x.hardware_id, 1)}
              onMouseOver={() => this.handleHover(idx, true)}
              onMouseOut={() => this.handleHover(idx, false)}
            >
              {x.vehicle_code}
              {this.state.btnHover[idx] ? (
                <Icon
                  type="close-circle"
                  style={{
                    position: "relative",
                    color: "#C41E3A",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                />
              ) : (
                <Icon type="down" />
              )}
            </Button>
          </Dropdown>
          //   </Col>
        );
      } else {
        return (
          <Button
            key={x.vehicle_code}
            style={{
              width: "180px",
              margin: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => this.handleOnClick(x.hardware_id)}
            onMouseOver={() => this.handleHover(idx, true)}
            onMouseOut={() => this.handleHover(idx, false)}
          >
            {x.vehicle_code}
            {this.state.btnHover[idx] ? (
              <Icon
                type="close-circle"
                style={{
                  position: "relative",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#C41E3A",
                }}
              />
            ) : (
              ""
            )}
          </Button>
        );
      }
    });
  }
}
