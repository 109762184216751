// etc
import watchUIState from "modules/ui/sagas";
import watchAuthState from "modules/auth/sagas";

// master data
import watchCustomerMasterDataState from "modules/master-data/customer/sagas";
import watchUsergroupMasterDataState from "modules/master-data/usergroup/sagas";
import watchVehiclepullsink from "modules/master-data/pullvehiclesink/sagas";
import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchDatavisibilityMasterDataState from "modules/master-data/datavisibility/sagas";
import watchPlaceMasterData from "modules/master-data/facility/sagas";
import watchUserMasterData from "modules/master-data/user/sagas";
import watchDataVehicleGroup from "modules/master-data/vehicleGroup/sagas";
import watchTemporaryTracking from "modules/master-data/temporaryTracking/sagas";
import watchRouteMasterData from "modules/master-data/route/sagas";
import watchVehicle from "modules/master-data/vehicle/sagas";
import watchVerify from "modules/master-data/verifyemail/sagas";
import watchDeviceUser from "modules/master-data/deviceuser/sagas";
import watchVehicleSharingMasterDataState from "modules/master-data/vehicle-sharing/sagas";
// import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchLocationGroup from "modules/master-data/locationgroup/sagas";
import watchLocationType from "modules/master-data/location-type/sagas";
import watchNearbyLocation from "modules/master-data/nearbylocation/sagas";
import watchZone from "modules/master-data/zone/sagas";
import watchRefuel from "modules/master-data/refuel/sagas";
import watchPartner from "modules/master-data/partner/sagas";
import watchLocationSharing from "modules/master-data/locations-sharing/sagas";
import watchPartType from "modules/master-data/parttype/sagas";
import watchPartGroup from "modules/master-data/partgroup/sagas";
import watchManagementMaintenance from "modules/master-data/maintenance/sagas";
import watchExpenseInsurance from "modules/master-data/insurance/sagas";
import watchCompany from "modules/master-data/company/sagas";
import watchDeviceConfig from "modules/master-data/deviceconfig/sagas";
import watchGallery from "modules/master-data/gallery/sagas";
import watchUom from "modules/master-data/uom/sagas";
import watchProduct from "modules/master-data/product/sagas";

// operation job
import watchGPSCertificationRequestState from "modules/operate-job/gps-certification/sagas";
import watchDeliveryItemMasterDataState from "modules/operate-job/delivery-item/sagas";
import watchDeliveryItemDeleteHistoryMasterDataState from "modules/operate-job/delivery-item-delete-history/sagas";

// help
import watchServicerepairdevice from "modules/help/servicerepairdevice/sagas";
import watchServiceMoveDevice from "modules/help/servicemovedevice/sagas";
import watchServiceInstallDevice from "modules/help/serviceeinstalldevice/sagas";
import watchManageRepairDevice from "modules/help/manage-repairdevice/sagas";
import watchMonitorSDCard from "modules/help/monitor-sdcard/sagas";

// booking
import watchBookingPoolState from "modules/booking-pool/sagas";

// dashboard
import watchMonitoringState from "modules/monitoring/sagas";
import watchVideoMonitoring from "modules/monitoring/video/sagas";
import watchHoneytoaststream from "modules/monitoring/honeytoaststream/sagas";
import watchHoneytoaststream2 from "modules/monitoring/honeytoaststream2/sagas";
import watchCashierState from "modules/cashier/sagas";
import watchMdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/sagas";
import watchHoneytoastdownloadfile from "modules/monitoring/honeytoastdownloadfile/sagas";
import watchHoneytoastplayback from "modules/monitoring/honeytoastplayback/sagas";
import watchCPFDashboard from "modules/monitoring/cpf-dashboard/sagas";

//management
import watchoutofcontact from "modules/management/outofcontact/sagas";
import watchDownloadcertificate from "modules/management/downloadcertificate/sagas";
import watchContactmanagement from "modules/management/contactmanagement/sagas";
import watchcreatefrimware from "modules/management/create-frimware/sagas";
import watchassignfirmware from "modules/management/assign-firmware/sagas";
import watchmanagefuelrate from "modules/management/manage-fuelrate/sagas";

// report
import watchAlarmAlertByPlateno from "modules/reports/alarmalertbyplateno/sagas";
import watchAlarmAlertByPlatenoOnyear from "modules/reports/alarmalertbyplatenoonyear/sagas";
import watchAlarmAlertByDriver from "modules/reports/alarmalertbydriver/sagas";
import watchAlarmAlertByDurationtime from "modules/reports/alarmalertbydurationtime/sagas";
import watchDrivingbehavior from "modules/reports/drivingbehavior/sagas";
import watchFuelremainrate from "modules/reports/fuelremainrate/sagas";
import watchGpsLostContact from "modules/reports/gpslostcontact/sagas";
import watchTaxiSos from "modules/reports/taxisos/sagas";
import watchTaxifare from "modules/reports/taxifare/sagas";
import watchSummaryLineNotification from "modules/reports/summarylinenotification/sagas";
import watchSpeedOverLimit from "modules/reports/speedoverlimit/sagas";
import watchDrivinglicensescanbehavior from "modules/reports/drivinglicensescanbehavior/sagas";
import watchSeparatevehiclestatus from "modules/reports/separatevehiclestatus/sagas";
import watchAlarmAlertFrequencyByDriver from "modules/reports/alarmalertfrequencybydriver/sagas";
import watchComparevehiclescco from "modules/reports/comparevehiclescco/sagas";
import watchAlertDriverLicense from "modules/reports/alertdriverlicense/sagas";
import watchParkingareaoutside from "modules/reports/parkingareaoutside/sagas";
import watchParking from "modules/reports/parking/sagas";
import watchDrivinglicensescanlog from "modules/reports/drivinglicensescanlog/sagas";
import watchSpeedoverlimitsummary from "modules/reports/speedoverlimitsummary/sagas";
import watchDrivinglog from "modules/reports/drivinglog/sagas";
import watchDrivinglogspecial from "modules/reports/drivinglogspecial/sagas";
import watchEvent from "modules/reports/event/sagas";
import watchSummarykpi from "modules/reports/summarykpi/sagas";
import watchSummarykpiByDriver from "modules/reports/summarykpibydriver/sagas";
import watchLoginhistory from "modules/reports/loginhistory/sagas";
import watchGpsstatus from "modules/reports/gpsstatus/sagas";
import watchTrackingdata from "modules/reports/trackingdata/sagas";
import watchTruckusage from "modules/reports/truckusage/sagas";
import watchDrivingdistance from "modules/reports/drivingdistance/sagas";
import watchTemperature from "modules/reports/temperature/sagas";
import watchMaintenance from "modules/reports/maintenance/sagas";
import watchDistfromlastmaintenance from "modules/reports/distfromlastmaintenance/sagas";
import watchTruckcurrentlocation from "modules/reports/truckcurrentlocation/sagas";
import watchDriverinformation from "modules/reports/driverinformation/sagas";
import watchNumberofdaysworked from "modules/reports/numberofdaysworked/sagas";
import watchDozeoffrisk from "modules/reports/dozeoffrisk/sagas";
import watchDelivery from "modules/reports/delivery/sagas";
import watchTriptemperature from "modules/reports/triptemperature/sagas";
import watchTripsumbyveh from "modules/reports/tripsumbyveh/sagas";
import watchDaily from "modules/reports/daily/sagas";
import watchVelocitysummary from "modules/reports/velocitysummary/sagas";
import watchTruckengineon from "modules/reports/truckengineon/sagas";
import watchTruckengineoff from "modules/reports/truckengineoff/sagas";
import watchTrucknotuse from "modules/reports/trucknotuse/sagas";
import watchDrivinghour from "modules/reports/drivinghour/sagas";
import watchSchedulereport from "modules/reports/schedulereport/sagas";
import watchBoatDeliveryReport from "modules/reports/boatdelivery/sagas";
import watchDrivinglicenseexpire from "modules/reports/drivinglicenseexpire/sagas";

import watchTripState from "modules/delivery-management/trip/sagas";
import watchDeliveryTripState from "modules/delivery-management/delivery-trip/sagas";
import watchRoutemasterState from "modules/delivery-management/route-master/sagas";

import watchTripsummary from "modules/reports/tripsummary/sagas";
import watchDrivingoverlimit from "modules/reports/drivingoverlimit/sagas";
import watchTripsumbylocation from "modules/reports/tripsumbylocation/sagas";
import watchInsurance from "modules/reports/insurance/sagas";
import watchVehicleperformanceconclude from "modules/reports/vehicleperformanceconclude/sagas";
import watchReportRefuel from "modules/reports/refuel/sagas";
import watchRiskbehaviour from "modules/reports/riskbehaviour/sagas";
import watchVehicleinarea from "modules/reports/vehicleinarea/sagas";
import watchMaintenancedeviceconclude from "modules/reports/maintenancedeviceconclude/sagas";
import watchVehicleinformationdlt from "modules/reports/vehicleinformationdlt/sagas";
import watchTripbyengineoffon from "modules/reports/tripbyengineoffon/sagas";

import watchPolicyState from "modules/setting/policy/sagas";
import watchFuelFullReport from "modules/reports/fuelfull/sagas";

import watchCalibratefuelState from "modules/setting/calibratefuel/sagas";

import watchSJCMonthlyVehicleUseage from "modules/reports/sjcmonthlytruckusages/sagas";
import watchDrivingcontinuous from "modules/reports/drivingcontinuous/sagas";
import watchNotinotswipecard from "modules/reports/notinotswipecard/sagas";

import watchDriverincome from "modules/reports/driverincome/sagas";
import watchGraphSensors from "modules/monitoring/graph-sensors/sagas";

import watchPerformanceLogReport from "modules/reports/performanceLog/sagas";

import watchManageOTP from "modules/management/manage-otp/sagas";
import watchInoutlocation from "modules/reports/inoutlocation/sagas";
import watchDepositfuelcredit from "modules/management/deposit-fuel-credit/sagas";
import watchWithdrawfuelcredit from "modules/management/withdraw-fuel-credit/sagas";
import watchreportwithdrawfuelcredit from "modules/reports/withdrawfuelcreditreport/sagas";
import watchreportdepositfuelcredit from "modules/reports/depositfuelcreditreport/sagas";
import watchreportflow from "modules/reports/flowreport/sagas";
import watchmanagedashboard from "modules/management/manage-dashboard-monitoring/sagas";
import watchdashboardmonitoring from "modules/monitoring/dashboard-monitoring/sagas";
import watchcompanysetting from "modules/setting/companysetting/sagas";
import watchmanagedocument from "modules/master-data/manage-document/sagas";

//import watchTruckusage from "modules/reports/MoveReport/Truckusage/sagas";
//import watchDrivingdistance from "modules/reports/MoveReport/drivingdistance/sagas";

import watchInputactivity from "modules/reports/inputactivity/sagas";
import watchSummaryDrivinglog from "modules/reports/summarydrivinglog/sagas";
import watchPickupAndDropoffEmployee from "modules/reports/pickupanddropoffemployee/sagas";

import watchCameraStatusDashboard from "modules/monitoring/camera-status-dashboard/sagas";
import watchMonitorGPS from "modules/monitoring/monitor-gps/sagas";

import { fork, all } from "redux-saga/effects";

export default function* rootSagas() {
  yield all([
    fork(watchUIState),
    fork(watchBookingPoolState),
    fork(watchAuthState),
    fork(watchMonitoringState),
    fork(watchCashierState),
    fork(watchCustomerMasterDataState),
    fork(watchAlarmAlertByPlateno),
    fork(watchAlarmAlertByPlatenoOnyear),
    fork(watchGPSCertificationRequestState),
    fork(watchAlarmAlertByDriver),
    fork(watchAlarmAlertByDurationtime),
    fork(watchDrivingbehavior),
    fork(watchDrivinglicensescanbehavior),
    fork(watchSeparatevehiclestatus),
    fork(watchComparevehiclescco),
    fork(watchAlertDriverLicense),
    fork(watchParkingareaoutside),
    fork(watchUsergroupMasterDataState),
    fork(watchDeliveryItemMasterDataState),
    fork(watchDeliveryItemDeleteHistoryMasterDataState),
    fork(watchVehiclepullsink),
    fork(watchSpeedoverlimitsummary),
    fork(watchSummarykpi),
    fork(watchPermissionMasterDataState),
    fork(watchDatavisibilityMasterDataState),
    fork(watchPlaceMasterData),
    fork(watchUserMasterData),
    fork(watchDataVehicleGroup),
    fork(watchRouteMasterData),
    fork(watchVehicle),
    fork(watchVehicleSharingMasterDataState),
    fork(watchAlarmAlertFrequencyByDriver),
    fork(watchParking),
    fork(watchLoginhistory),
    fork(watchGpsstatus),
    fork(watchTrackingdata),
    fork(watchVideoMonitoring),
    fork(watchTruckusage),
    fork(watchDrivingdistance),
    fork(watchDrivinglicensescanlog),
    fork(watchDrivinglog),
    fork(watchEvent),
    fork(watchVerify),
    fork(watchDeviceUser),
    fork(watchLocationGroup),
    fork(watchLocationType),
    fork(watchNearbyLocation),
    fork(watchZone),
    fork(watchRefuel),
    fork(watchTemperature),
    fork(watchMaintenance),
    fork(watchDistfromlastmaintenance),
    fork(watchTruckcurrentlocation),
    fork(watchDriverinformation),
    fork(watchNumberofdaysworked),
    fork(watchDozeoffrisk),
    fork(watchDelivery),
    fork(watchTriptemperature),
    fork(watchTripsumbyveh),
    fork(watchDaily),
    fork(watchVelocitysummary),
    fork(watchTruckengineon),
    fork(watchTruckengineoff),
    fork(watchInputactivity),
    fork(watchTrucknotuse),
    fork(watchDrivinghour),
    fork(watchTripsummary),
    fork(watchDrivingoverlimit),
    fork(watchTripsumbylocation),
    fork(watchInsurance),
    fork(watchVehicleperformanceconclude),
    fork(watchReportRefuel),
    fork(watchRiskbehaviour),
    fork(watchPartner),
    fork(watchLocationSharing),
    fork(watchVehicleinarea),
    fork(watchMaintenancedeviceconclude),
    fork(watchDownloadcertificate),
    fork(watchPolicyState),
    fork(watchTripState),
    fork(watchPartType),
    fork(watchPartGroup),
    fork(watchManagementMaintenance),
    fork(watchContactmanagement),
    fork(watchMdvrdownloadfile),
    fork(watchServicerepairdevice),
    fork(watchDeliveryTripState),
    fork(watchExpenseInsurance),
    fork(watchVehicleinformationdlt),
    fork(watchCompany),
    fork(watchDeviceConfig),
    fork(watchServiceMoveDevice),
    fork(watchHoneytoaststream),
    fork(watchHoneytoaststream2),
    fork(watchServiceInstallDevice),
    fork(watchHoneytoastdownloadfile),
    fork(watchHoneytoastplayback),
    fork(watchDrivinglogspecial),
    fork(watchTripbyengineoffon),
    fork(watchGallery),
    fork(watchSchedulereport),
    fork(watchRoutemasterState),
    fork(watchBoatDeliveryReport),
    fork(watchSummarykpiByDriver),
    fork(watchFuelremainrate),
    fork(watchGpsLostContact),
    fork(watchTaxiSos),
    fork(watchSummaryLineNotification),
    fork(watchSpeedOverLimit),
    fork(watchcreatefrimware),
    fork(watchassignfirmware),
    fork(watchFuelFullReport),
    fork(watchTaxifare),
    fork(watchTemporaryTracking),
    fork(watchUom),
    fork(watchProduct),
    fork(watchCalibratefuelState),
    fork(watchSJCMonthlyVehicleUseage),
    fork(watchDrivingcontinuous),
    fork(watchNotinotswipecard),
    fork(watchDriverincome),
    fork(watchManageRepairDevice),
    fork(watchGraphSensors),
    fork(watchPerformanceLogReport),
    fork(watchManageOTP),
    fork(watchCPFDashboard),
    fork(watchoutofcontact),
    fork(watchInoutlocation),
    fork(watchmanagefuelrate),
    fork(watchDepositfuelcredit),
    fork(watchreportwithdrawfuelcredit),
    fork(watchWithdrawfuelcredit),
    fork(watchreportdepositfuelcredit),
    fork(watchreportflow),
    fork(watchmanagedashboard),
    fork(watchdashboardmonitoring),
    fork(watchSummaryDrivinglog),
    fork(watchcompanysetting),
    fork(watchDrivinglicenseexpire),
    fork(watchPickupAndDropoffEmployee),
    fork(watchmanagedocument),
    fork(watchMonitorSDCard),
    fork(watchCameraStatusDashboard),
    fork(watchMonitorGPS),
  ]);
}
