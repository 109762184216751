import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'
import ViewDetail from './ViewDetail'

export default () => (
  <Switch>
    <Route exact path="/monitor-gps" component={Index} />
    <Route
      exact
      path="/monitor-gps/view-detail/:vehicle_id/:active_date"
      component={ViewDetail}
    />
  </Switch>
)
